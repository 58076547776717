import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { ModalConfirm } from "tamtam-components";
import { useNavigate } from "react-router-dom";

import { deleteMemberShip, getMemberShips } from "../../api";
import _ from "../../i18n";

import Sidebar from "./Sidebar";
import MemberShip from "./MemberShip";
import styles from "./ManagePacks.module.scss";

const ManageMemberShips = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const auth = useSelector((state) => state.auth);
  const { token } = auth;

  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentMemberShip, setCurrentMemberShip] = useState(null);
  const [deleting, setDeleting] = useState(false);

  let listMemberShips = useQuery(["listMemberShips", token], async () => {
    if (token) {
      try {
        const response = await getMemberShips(token);
        return response.data.data;
      } catch (e) {
        return null;
      }
    }
  });

  const onConfirmRateDelete = async () => {
    try {
      setDeleting(true);
      await deleteMemberShip(auth.token, currentMemberShip.id);
      queryClient.invalidateQueries("listMemberShips");
      setCurrentMemberShip(null);
      setDeleteModalOpen(false);
    } catch (e) {}
    setDeleting(false);
  };

  return (
    <div className={styles.ratesPage}>
      <div className={styles.header}>
        <div>
          <h1>{_("memberships_list")}</h1>
          <span>{_("manage_memberships")}</span>
        </div>
      </div>
      {listMemberShips.isLoading && <div className="lmask"></div>}
      {!listMemberShips.isLoading && !listMemberShips.data && (
        <div
          style={{
            textAlign: "center",
            maxWidth: "600px",
            margin: "0 auto 60px",
            color: "#2C394B",
            fontSize: "2rem",
          }}
        >
          <img alt="not found" src="/img/notFound.svg" />
          <div>{_("nothing_found")}</div>
        </div>
      )}

      {!listMemberShips.isLoading && listMemberShips.data && (
        <div className="grid-x grid-margin-x grid-margin-y">
          {listMemberShips.data.map((memberShip) => (
            <div className="cell small-12 medium-6 large-4" key={memberShip.id}>
              <MemberShip
                memberShip={memberShip}
                onEdit={(p) => {
                  setCurrentMemberShip(p);
                  setSidebarOpened(true);
                }}
              />
            </div>
          ))}
        </div>
      )}

      <ModalConfirm
        type="delete"
        isOpen={deleteModalOpen}
        inProcess={deleting}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={onConfirmRateDelete}
        title={_("delete_confirm")}
        text={_("text_delete_rate")}
        labelNo={_("no")}
        labelYes={_("yes")}
      />
      <div className={styles.bubbles}>
        <div
          onClick={() => {
            setSidebarOpened(true);
          }}
        >
          <i className="icon-ttp-plus-outline"></i>
          <span style={{ paddingLeft: "1rem" }}>{_("add")}</span>
        </div>
      </div>
      <Sidebar
        isOpened={sidebarOpened}
        hideSidebar={() => {
          setSidebarOpened(false);
          setCurrentMemberShip(null);
          queryClient.invalidateQueries("listMemberShips");
        }}
        sidebarClass="sidebar"
        currentMemberShip={currentMemberShip}
        setCurrentMemberShip={setCurrentMemberShip}
      />
    </div>
  );
};

export default ManageMemberShips;
