const i18nEn = {
  continue: "Continue",
  signup: "Register",
  signin: "Login",
  forgot_password: "Forgot?",
  must_accept_conditions: "You must accept our terms of use",
  already_member: "Already a member?",
  server_error: "Server unreachable - Try again later",
  invalid_credentials: "Invalid credentials",
  check_email_help_txt:
    "For any questions or suggestions, or if you encounter a connection problem, the United Associates team is at your disposal via the address info@unitedassociates.be or by telephone at 0473/229.313.",
  email_address: "Email Address",
  email: "Email",
  firstname: "First name",
  lastname: "Name",
  validate_email: "Please provide a valid email address",
  validate_password: "Required password",
  required_field: "This field is required",
  required_2_characters: "Please provide at least 2 characters",
  password_weak: "The password is weak!",
  password_not_match: "Please enter the same value again",
  password: "Password",
  confirm_password: "Confirm password",
  check_your_email: "Please check your email to validate your address",
  already_sent_email:
    "We have already sent a confirmation email to your inbox.",
  resend_confirmation_email: "Resend a confirmation email",
  create_account: "Create account",
  send: "Send",
  password_changed_successfully: "Password saved successfully",
  reset_pwd_check_your_email:
    "A message has been sent to your email. Please review it and follow the instructions.",
  successfully_saved: "Saved successfully",
  complete_personal_infos: "Complete my personal account",
  mobile_phone_number: "Mobile Phone Number",
  email_validated_successfully: "Email address validated successfully",
  account_exist_login:
    "This account already exists in the Tamtam universe:<br/><ul><li><small>- oFFFcouse</small></li><li><small>- United Associates</small></li ><li><small>- Forum For the Future</small></li></ul>Please enter your password",
  account_exist_pwd:
    "This account already exists in the Tamtam universe:<br/><ul><li><small>- oFFFcouse</small></li><li><small>- United Associates</small></li ><li><small>- Forum For the Future</small></li></ul>Please set a password",
  validate: "Validate",
  male: "Man",
  female: "Woman",
  gender: "Gender",
  language: "Language",
  numeroAgreation: "Agreement number (economic professions)",
  itaa: "ITAA Office",
  ire_audit: "IRE audit firm",
  law_office: "Law office",
  notarial_office: "Notarial office",
  other: "Others...",
  validate_phone: "The phone number is invalid",
  uen_label_number: "Enter the number",
  oldPassword_incorrect: "Incorrect old password",
  email_not_unique: "Email address already taken",
  cancel: "Cancel",
  english: "English",
  french: "French",
  dutch: "Dutch",
  reset_password: "Set password",
  help: "FAQ / Help",
  formula: "Formula",
  select: "Select",
  selected: "Selected",
  uen_info:
    "Unique company number, also known as KBO or BCE. We use the format <span>BE XXXX.XXX.XXX</span>. This value is used to uniquely identify companies on our platform. ",
  validate_uen: "Please provide a valid number",
  business_number: "Business number",
  organization: "Organization",
  address: "Address",
  city: "City",
  postalCode: "Postal Code",
  select_related_formula:
    "Please select the %formula1% formula to be able to register for the %formula2% formula",
  add_related_formula: "Add formula %formula%",
  email_link_expired: "Sorry, this link has expired. Please log in!",
  oeccbb_formulas:
    "The formulas linked to your membership in the community of the Order (OECCBB)",
  activated: "Activated",
  summary: "Summary",
  no_selected_formula: "No formula has been selected",
  select_formula_membership:
    "Select at least one plan to activate your membership",
  year: "year",
  already_paid: "Already paid",
  total_to_pay: "Total to pay",
  proceed_payment: "Proceed to payment",
  billing: "Billing",
  payment: "Payment",
  subject_to_vat: "Subject to VAT",
  not_subject_to_vat: "Not subject to VAT",
  pay: "Pay",
  manage_memberships: "Manage memberships",
  memberships_list: "List of membership formulas",
  nothing_found: "Nothing found...",
  add: "Add",
  add_new_membership: "Add a new membership",
  htva: "excl. VAT",
  member_fee: "Member fee",
  finish: "Finish",
  must_accept_privacy: "You must accept the Privacy Terms",
};
export default i18nEn;
