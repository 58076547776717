import React, { useEffect, useState, useRef } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";

import FormInput from "../common/FormInput";
import Button from "../common/Button";
import Loader from "../common/Loader";
import AgreationNumber from "../common/AgreationNumber";
import Checkbox from "../common/Checkbox";
import _ from "../../i18n";

import {
  LANGUAGES,
  SELECT_STYLES_LARGE,
  AGREATION_OPTIONS,
} from "../../config";
import {
  getClientCredential,
  postUserEmail,
  saveUser,
  postUserCredential,
} from "../../api";
import { setAuthToken, setAuthTokenUser } from "../../store";
import {
  validateEmail,
  cleanEmail,
  validatePhone,
  validatePassword,
  formatPhone,
  logout,
} from "../../utils";

import styles from "./Register.module.scss";

const GENDER_OPTIONS = [
  { value: "MALE", label: _("male") },
  { value: "FEMALE", label: _("female") },
];

const ErrorMsg = ({ msg }) => {
  return <div dangerouslySetInnerHTML={{ __html: msg }}></div>;
};

const Register = () => {
  const auth = useSelector((state) => state.auth);
  const lng = useSelector((state) => state.params.language);
  const backUrl = useSelector((state) => state.params.backUrl);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const inputRef = useRef();

  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [hasPassword, setHasPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [language, setLanguage] = useState(LANGUAGES[0]);
  const [gender, setGender] = useState("");
  const [numeroAgreation, setNumeroAgreation] = useState("");
  const [agreation, setAgreation] = useState(AGREATION_OPTIONS[0]);
  const [showCheckEmail, setShowCheckEmail] = useState(false);
  const [isNewEmail, setIsNewEmail] = useState(true);
  const [step, setStep] = useState("step1");
  const [isAccepted, setIsAccepted] = useState(false);
  const [pwdProgressValue, setPwdProgressValue] = useState(0);
  const [errors, setErrors] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    phone: "",
  });
  const [acceptError, setAcceptError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  // const [source, setSource] = useState("");

  useEffect(() => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");

    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === "email") {
        logout(false);
        setEmail(decodeURIComponent(pair[1]));
        setStep("step2");
        toast.success(_("email_validated_successfully"));
      }
      if (pair[0] === "uid") {
        setId(decodeURIComponent(pair[1]));
      }
      // if (pair[0] === "source") {
      //   setSource(decodeURIComponent(pair[1]));
      // }
    }

    if (!auth.token) {
      getClientCredential()
        .then((resp) => {
          const helpResp = typeof resp === "string" ? JSON.parse(resp) : resp;
          const token = helpResp.token.access_token;
          dispatch(setAuthToken(token));
        })
        .catch((e) => {});
    }
  }, []);

  const handleBlurEmail = (e) => {
    if (!validateEmail(cleanEmail(email))) {
      setErrors({ ...errors, email: _("validate_email") });
    } else {
      setErrors({ ...errors, email: "" });
    }
  };

  const handlePhoneBlur = (e) => {
    setPhone(formatPhone(phone));
  };

  const handleCheckboxClick = (e) => {
    e.stopPropagation();
    setIsAccepted(!isAccepted);
  };

  const handleValidateEmail = () => {
    const emailStr = cleanEmail(email);
    if (!validateEmail(emailStr)) {
      setErrors({ ...errors, email: _("validate_email") });
      return null;
    }

    setIsSaving(true);
    postUserEmail(auth.token, emailStr, lng, backUrl)
      .then((resp) => {
        if (resp.data.data.success) {
          if (resp.data.data.data.status === "VALIDATED") {
            setEmail(resp.data.data.data.email);
            setStep("step2");
          } else {
            setShowCheckEmail(true);
            if (!resp.data.data.isNew) {
              setIsNewEmail(false);
            }
          }
        } else {
          if (resp.data.data.data) {
            if (resp.data.data.data.hasPwd) {
              toast.info(<ErrorMsg msg={_("account_exist_login")} />, {
                autoClose: 15000,
              });
              const params = { email: emailStr };
              if (backUrl) {
                params.back_url = backUrl;
              }
              navigate({
                pathname: "/login",
                search: createSearchParams(params).toString(),
              });
            } else {
              toast.info(<ErrorMsg msg={_("account_exist_pwd")} />, {
                autoClose: 15000,
              });
              setShowCheckEmail(true);
            }
          }
        }
        setIsSaving(false);
      })
      .catch((e) => {
        setIsSaving(false);
      });
  };

  const handleKeyUp = (fieldName, fieldValue) => {
    let value = "";
    if (fieldValue.length === 0) {
      value = _("required_field");
    } else {
      switch (fieldName) {
        case "phone":
          const result = validatePhone(fieldValue);
          if (!result.valid) {
            value = _("validate_phone");
          }
          break;
        case "lastName":
        case "firstName":
          if (fieldValue.length < 2) {
            value = _("required_2_characters");
          }
          break;
        case "password":
          var pwdResult = validatePassword(password, [
            email,
            firstName,
            lastName,
          ]);

          if (pwdResult.strength > 30) {
            setPwdProgressValue(pwdResult.strength);
          } else {
            value = _("password_weak");
            if (pwdResult.strength < 4) {
              setPwdProgressValue(pwdResult.strength + 25);
            } else {
              setPwdProgressValue(pwdResult.strength - 0.01);
            }
          }
          break;
        case "confirmPassword":
          if (fieldValue !== password) {
            value = _("password_not_match");
          }
          break;
      }
    }
    setErrors({ ...errors, [fieldName]: value });
    return value;
  };

  const validate = () => {
    let tabErrors = {};
    tabErrors.lastName = handleKeyUp("lastName", lastName);
    tabErrors.firstName = handleKeyUp("firstName", firstName);
    tabErrors.phone = handleKeyUp("phone", phone);
    tabErrors.password = !hasPassword ? handleKeyUp("password", password) : "";
    tabErrors.confirmPassword = !hasPassword
      ? handleKeyUp("confirmPassword", confirmPassword)
      : "";

    setErrors(tabErrors);

    setAcceptError(!isAccepted);

    return tabErrors.lastName ||
      tabErrors.firstName ||
      tabErrors.phone ||
      tabErrors.password ||
      tabErrors.confirmPassword ||
      !isAccepted
      ? true
      : false;
  };

  const save = () => {
    let error = validate();
    if (error) {
      return null;
    }

    const data = {
      id,
      email,
      firstName,
      lastName,
      phone,
      language: language.value,
      gender: gender ? gender.value : "",
      password: password !== "**********" ? password : "",
      confirmPassword: confirmPassword !== "**********" ? confirmPassword : "",
      agreation: numeroAgreation
        ? JSON.stringify({
            type: agreation.value,
            number: numeroAgreation,
          })
        : "",
    };

    setIsSaving(true);
    saveUser(auth.token, data)
      .then((resp) => {
        postUserCredential({
          email,
          password,
        }).then((authResponse) => {
          dispatch(setAuthTokenUser(authResponse.data));
          if (backUrl) {
            navigate({
              pathname: "/offers",
              search: createSearchParams({ back_url: backUrl }).toString(),
            });
          } else {
            navigate("/offers");
          }
          setIsSaving(false);
        });
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        ) {
          toast.error(_(e.response.data.errors[0].message));
        } else {
          toast.error(_("error"));
        }

        setIsSaving(false);
      });
  };

  const changePassword = () => {
    setPassword("");
    setConfirmPassword("");
    setHasPassword(false);

    setTimeout(() => {
      inputRef.current.focus();
    });
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.rightSide}>
          <div className={styles.topButtons}>
            <span>{_("already_member")}</span>
            <span
              onClick={() => navigate("/login")}
              className={styles.register}
            >
              {_("signin")}
            </span>
          </div>

          {step === "step1" && (
            <div className={styles.content}>
              <h1 className={styles.title}>{_("signup")}</h1>

              {showCheckEmail ? (
                <p className={styles.successMsg}>
                  <i className="icon-ttp-check-outline"></i>
                  {isNewEmail ? (
                    <span>{_("check_your_email")}</span>
                  ) : (
                    <>
                      <span>{_("already_sent_email")}</span>
                      <span className={styles.successMsg_send}>
                        {_("resend_confirmation_email")}
                      </span>
                    </>
                  )}
                </p>
              ) : (
                <>
                  <FormInput
                    name="email"
                    value={email}
                    label={_("email")}
                    error={errors.email}
                    className="ttp-input-lg"
                    labelClassName="ttp-label-lg"
                    // autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={(e) => handleBlurEmail(e)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleValidateEmail();
                      }
                    }}
                  />

                  <div className={styles.actions}>
                    {isSaving ? (
                      <Button
                        variant="primary"
                        style={{ paddingTop: "15px", paddingBottom: "15px" }}
                      >
                        <Loader
                          style={{
                            height: "10px",
                          }}
                          color={"#fff"}
                        />
                      </Button>
                    ) : (
                      <Button onClick={handleValidateEmail}>
                        {_("continue")}
                      </Button>
                    )}
                  </div>
                </>
              )}
              <p className={styles.helpText}>
                Si vous détenez un compte TamTam, veuillez utiliser votre
                adresse.
              </p>
              <p className={styles.helpText}>{_("check_email_help_txt")}</p>
            </div>
          )}

          {step === "step2" && (
            <div className={styles.contentLg}>
              <h1 className={styles.title}>
                {step === "step1" ? _("signup") : _("complete_personal_infos")}
              </h1>

              <div className={styles.contentLg_grid}>
                <div className={styles.contentLg_left}>
                  <div className={styles.emailInputBox}>
                    <FormInput
                      name="email"
                      value={email}
                      label={_("email_address")}
                      disabled={true}
                      autocomplete="username"
                      hideLockIcon={true}
                      className={`${styles.emailInput} ttp-input-lg`}
                      labelClassName="ttp-label-lg"
                    />
                    <i className="icon-ttp-check-outline"></i>
                  </div>
                  <div className="grid-x grid-margin-x">
                    <div className="cell small-12 medium-6">
                      <FormInput
                        name="lastName"
                        required={true}
                        label={_("lastname")}
                        autoComplete="off"
                        error={errors.lastName}
                        value={lastName}
                        className="ttp-input-lg"
                        labelClassName="ttp-label-lg"
                        onKeyUp={(e) =>
                          handleKeyUp(e.target.name, e.target.value)
                        }
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                    <div className="cell small-12 medium-6">
                      <FormInput
                        name="firstName"
                        required={true}
                        label={_("firstname")}
                        autoComplete="off"
                        error={errors.firstName}
                        value={firstName}
                        className="ttp-input-lg"
                        labelClassName="ttp-label-lg"
                        onKeyUp={(e) =>
                          handleKeyUp(e.target.name, e.target.value)
                        }
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={styles.btnChangePwd}>
                    {hasPassword && (
                      <span onClick={changePassword}>
                        {_("change_password")}
                      </span>
                    )}
                    <div className={styles.passwordBox}>
                      <FormInput
                        inputRef={inputRef}
                        name="password"
                        value={password}
                        label={_("password")}
                        type="password"
                        required={true}
                        autoComplete="off"
                        error={errors.password}
                        disabled={hasPassword}
                        className="ttp-input-lg"
                        labelClassName="ttp-label-lg"
                        onKeyUp={(e) =>
                          handleKeyUp(e.target.name, e.target.value)
                        }
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    {password && !hasPassword && (
                      <progress
                        className={`${
                          pwdProgressValue > 30 ? "success" : "alert"
                        } ${styles.progress}`}
                        max="100"
                        value={pwdProgressValue}
                      ></progress>
                    )}
                  </div>

                  <FormInput
                    name="confirmPassword"
                    value={confirmPassword}
                    label={_("confirm_password")}
                    type="password"
                    required={true}
                    autocomplete="new-password"
                    disabled={hasPassword}
                    className="ttp-input-lg"
                    labelClassName="ttp-label-lg"
                    error={errors.confirmPassword}
                    onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>

                <div className={styles.contentLg_right}>
                  <FormInput
                    name="phone"
                    required={true}
                    label={_("mobile_phone_number")}
                    placeholder="+32XXXXXXXX"
                    autoComplete="off"
                    value={phone}
                    error={errors.phone}
                    className="ttp-input-lg"
                    labelClassName="ttp-label-lg"
                    onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
                    onChange={(e) => setPhone(e.target.value)}
                    handleBlur={(e) => handlePhoneBlur(e)}
                  />

                  <div className="grid-x grid-margin-x">
                    <div className="cell small-12 medium-6">
                      <div className="ttp-form-group-h">
                        <label className="ttp-label ttp-label-lg">
                          {_("gender")}
                        </label>
                        <Select
                          styles={SELECT_STYLES_LARGE}
                          options={GENDER_OPTIONS}
                          isSearchable={false}
                          isClearable={true}
                          value={gender}
                          onChange={(e) => setGender(e)}
                        />
                      </div>
                    </div>
                    <div className="cell small-12 medium-6">
                      <div className="ttp-form-group-h">
                        <label className="ttp-label ttp-label-lg">
                          {_("language")}
                        </label>
                        <Select
                          styles={SELECT_STYLES_LARGE}
                          options={LANGUAGES}
                          isSearchable={false}
                          value={language}
                          onChange={(e) => setLanguage(e)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="ttp-form-group-h">
                    <label className="ttp-label ttp-label-lg">
                      {_("numeroAgreation")}
                    </label>
                    {/* <div className={styles.agreationBox}>
                      <Select
                        styles={SELECT_INPUT_STYLES}
                        options={AGREATION_OPTIONS}
                        className={styles.agreationSelect}
                        isSearchable={false}
                        value={agreation}
                        onChange={(e) => setAgreation(e)}
                      />

                      <div className={styles.agreation}>
                        <InputMask
                          mask="99 . 999 . 999"
                          maskChar="_"
                          alwaysShowMask={true}
                          name="numeroAgreation"
                          value={numeroAgreation}
                          onChange={(e) => setNumeroAgreation(e.target.value)}
                        />
                      </div>
                    </div> */}

                    <AgreationNumber
                      NumeroAgreation={numeroAgreation}
                      Agreation={agreation}
                      setAgreation={(value) => setAgreation(value)}
                      setNumeroAgreation={(value) => setNumeroAgreation(value)}
                      // small={true}
                      showNumberLabel={true}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.acceptActions}>
                <Checkbox checked={isAccepted} onClick={handleCheckboxClick} />
                <label
                  className={styles.checkbox}
                  onClick={handleCheckboxClick}
                >
                  <span>
                    La création d'un compte signifie que vous êtes d'accord avec
                    nos{" "}
                    <a
                      target="_blank"
                      href="https://help.tamtam.pro/fr/privacy/terms_of_use"
                      className={styles.link}
                    >
                      conditions d'utilisation
                    </a>
                    , notre{" "}
                    <a
                      target="_blank"
                      href="https://help.tamtam.pro/fr/privacy"
                      className={styles.link}
                    >
                      politique de confidentialité
                    </a>
                    .
                  </span>
                </label>
              </div>
              {acceptError && (
                <p className={styles.acceptError}>
                  {_("must_accept_conditions")}
                </p>
              )}

              <div className={styles.actions}>
                {isSaving ? (
                  <Button
                    variant="primary"
                    style={{ paddingTop: "15px", paddingBottom: "15px" }}
                  >
                    <Loader
                      style={{
                        height: "10px",
                      }}
                      color={"#fff"}
                    />
                  </Button>
                ) : (
                  <Button onClick={save}>{_("create_account")}</Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Register;
