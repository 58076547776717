import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import $ from "jquery";

import {
  loginOrClientToken,
  setAuthUser,
  setAuthToken,
  setExpiresIn,
  setTokenCreatedAt,
  fetchAutoAuthUser,
  setCallParentAuth,
} from "../../store";
import { logout, decryptWithAES } from "../../utils";
import Header from "./Header";
import _ from "../../i18n";

const Layout = ({ children }) => {
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const [hideHeader, setHideHeader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // axios.interceptors.response.use(
    //   (response) => {
    //     return response;
    //   },
    //   function (error) {
    //     if (error.response.status === 401) {
    //       dispatch(setAuthUser(null));
    //       logout(false);
    //       navigate("/login");
    //       return Promise.reject(error);
    //     }

    //     return Promise.reject(error);
    //   }
    // );

    let authInfos = null;
    const params = searchParams.get("params");
    if (params) {
      const decryptedParams = JSON.parse(decryptWithAES(params));
      if (
        decryptedParams["token"] &&
        decryptedParams["userId"] &&
        decryptedParams["tokenCreatedAt"] &&
        decryptedParams["tokenExpiresIn"]
      ) {
        authInfos = {
          id: decryptedParams["userId"],
          token: decryptedParams["token"],
          createdAt: decryptedParams["tokenCreatedAt"],
          expiresIn: decryptedParams["tokenExpiresIn"],
        };
        dispatch(setAuthToken(authInfos.token));
        dispatch(setExpiresIn(authInfos.expiresIn));
        dispatch(setTokenCreatedAt(authInfos.createdAt));
        dispatch(fetchAutoAuthUser(authInfos));

        searchParams.delete("params");
        setSearchParams(searchParams);
      }

      if (decryptedParams["noHeader"]) {
        setHideHeader(true);
      }
      if (decryptedParams["callAuth"]) {
        dispatch(setCallParentAuth(true));
      }
    }

    if (!authInfos) {
      dispatch(loginOrClientToken());
    }

    if (!auth.user) {
      $("#app-loader")
        .fadeOut()
        .promise()
        .done(() => $("#app-loader").remove());
    }
  }, []);

  return (
    <div>
      {!hideHeader && <Header />}
      <Outlet />
    </div>
  );
};

export default Layout;
