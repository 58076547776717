import { useState } from "react";
// import Slider from "@mui/material/Slider";

import _ from "../../i18n";
// import { YesIcon, NoIcon } from "../common/Icons";

import Tabs from "../common/Tabs";
import styles from "./MemberShip.module.scss";

const MemberShip = ({ memberShip, onSelect, onEdit }) => {
  const [currentTab, setCurrentTab] = useState("standard");

  return (
    <div className={styles.membership}>
      <div className={styles.membership_top}>
        <span
          className={`${styles.membership_status} ${
            styles[memberShip.status.toLowerCase()]
          }`}
        >
          {_(memberShip.status.toLowerCase())}
        </span>
      </div>
      <div className={`${styles.membership_bottom}`}>
        <Tabs theme="wtheme">
          <div
            key={`langtabfr`}
            label={_("french")}
            icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/fr.png`}
          >
            <div className={styles.membership_name}>{memberShip.nameFr}</div>
            <div className={styles.langTab}>{memberShip.descriptionFr}</div>
          </div>
          <div
            key={`langtabnl`}
            label={_("dutch")}
            icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/nl.png`}
          >
            <div className={styles.membership_name}>{memberShip.nameNl}</div>
            <div className={styles.langTab}>{memberShip.descriptionNl}</div>
          </div>
          <div
            key={`langtabfr`}
            label={_("english")}
            icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/en.png`}
          >
            <div className={styles.membership_name}>{memberShip.nameEn}</div>
            <div className={styles.langTab}>{memberShip.descriptionEn}</div>
          </div>
        </Tabs>
        <div className={styles.membership_actions}>
          <div
            className={styles.membership_btn}
            onClick={() => {
              onEdit(memberShip);
            }}
          >
            <i className="icon-ttp-edit" /> {_("edit")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberShip;
