import React from "react";

const IconInfo = ({ size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_69164_79492)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.9987 2.49479C5.85656 2.49479 2.4987 5.85266 2.4987 9.99479C2.4987 14.1369 5.85656 17.4948 9.9987 17.4948C14.1408 17.4948 17.4987 14.1369 17.4987 9.99479C17.4987 5.85266 14.1408 2.49479 9.9987 2.49479ZM0.832031 9.99479C0.832031 4.93218 4.93609 0.828125 9.9987 0.828125C15.0613 0.828125 19.1654 4.93218 19.1654 9.99479C19.1654 15.0574 15.0613 19.1615 9.9987 19.1615C4.93609 19.1615 0.832031 15.0574 0.832031 9.99479Z"
          fill="#6D7F92"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.0013 9.17188C10.4615 9.17188 10.8346 9.54497 10.8346 10.0052V13.3385C10.8346 13.7988 10.4615 14.1719 10.0013 14.1719C9.54106 14.1719 9.16797 13.7988 9.16797 13.3385V10.0052C9.16797 9.54497 9.54106 9.17188 10.0013 9.17188Z"
          fill="#6D7F92"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.16797 6.66146C9.16797 6.20122 9.54106 5.82812 10.0013 5.82812H10.0096C10.4699 5.82812 10.843 6.20122 10.843 6.66146C10.843 7.1217 10.4699 7.49479 10.0096 7.49479H10.0013C9.54106 7.49479 9.16797 7.1217 9.16797 6.66146Z"
          fill="#6D7F92"
        />
      </g>
      <defs>
        <clipPath id="clip0_69164_79492">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconInfo;
