import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import classnames from "classnames";

import { validateUen, formatUen } from "../../utils";
import { TTP_PAYMENT_URL } from "../../config";
import { addOrder, getLastBillingsInfos, getUserMemberShip } from "../../api";
import { setIsSuccessPayment, setOrderType } from "../../store";
import _ from "../../i18n";
import FormInput from "../common/FormInput";
import Button from "../common/Button";
import Checkbox from "../common/Checkbox";
import AuthInfos from "../AuthModal/AuthInfos";

import styles from "./PaymentModal.module.scss";

const customModalStyles = {
  content: {
    backgroundColor: "#fff",
    border: "none",
    overflow: "none",
    padding: 0,
    top: "50%",
    bottom: "unset",
    height: "90%",
    maxHeight: "700px",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px rgba(41, 57, 77, 0.04)",
    zIndex: 999,
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    width: "90%",
    maxWidth: "1296px",
  },
};

Modal.setAppElement("#root");

export default function PaymentModal({
  isOpen,
  closeModal,
  myMemberShips,
  memberShipOrder,
  setShowInfoBar,
}) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const language = useSelector((state) => state.params.language);
  const backUrl = useSelector((state) => state.params.backUrl);
  const [showTerminate, setShowTerminate] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState("AUTH"); // AUTH | USER_INFO | PAYMENT

  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [uen, setUen] = useState("");
  const [organization, setOrganization] = useState("");
  const [isAccepted, setIsAccepted] = useState(false);
  const [isPrivacyAccepted, setIsPrivacyAccepted] = useState(false);
  const [subjectToVAT, setSubjectToVAT] = useState(true);
  const [errors, setErrors] = useState({
    uen: "",
    organization: "",
    address: "",
    postalCode: "",
    city: "",
    approval: "",
    privacy: "",
  });

  useEffect(() => {
    const handler = (e) => {
      const eventId = e?.data?.event;
      const paymentIframe = document.getElementById("modal-payment-iframe");

      if (e?.data?.message === "CLOSE_PAYMENT_MODAL") {
        // modal.onSuccess?.();
        closeModal();
      }

      if (e?.data?.event === "RETRY_PAYMENT") {
        paymentIframe.src += ""; // To refresh the iframe
      }

      switch (eventId) {
        case "PAYMENT_ONLINE":
          setShowTerminate(false);
          break;
        case "PAYMENT_TRANSFER":
          setShowTerminate(true);
          break;
        case "PAYMENT_ORDERS":
          setShowTerminate(false);
          break;
        case "RETRY_PAYMENT":
          setShowTerminate(false);
          break;
      }
    };

    window.addEventListener("message", handler);

    return () => {
      window.removeEventListener("message", handler);
    };
  }, []);

  useEffect(() => {
    if (auth.user && isOpen) {
      setStep("USER_INFO");
    }
  }, [auth.user, isOpen]);

  useEffect(() => {
    if (isOpen && step === "USER_INFO") {
      if (memberShipOrder) {
        setAddress(memberShipOrder.billingStreet);
        setPostalCode(memberShipOrder.billingPostalCode);
        setOrganization(memberShipOrder.billingOrganization);
        setCity(memberShipOrder.billingCountry);
        setSubjectToVAT(memberShipOrder.billingSubjectToVAT);
        if (memberShipOrder.billingCompanyNumber) {
          const formattedUen = memberShipOrder.billingCompanyNumber.includes(
            "BE"
          )
            ? formatUen(memberShipOrder.billingCompanyNumber)
            : formatUen("BE" + memberShipOrder.billingCompanyNumber);
          setUen(formattedUen);
        }
      } else {
        setIsLoading(true);
        getLastBillingsInfos(token)
          .then((result) => {
            if (result.data.data) {
              const item = result.data.data;
              setAddress(item.billingStreet);
              setPostalCode(item.billingPostalCode);
              setOrganization(item.billingOrganization);
              setCity(item.billingCountry);
              setSubjectToVAT(item.billingSubjectToVAT);
              if (item.billingCompanyNumber) {
                const formattedUen = item.billingCompanyNumber.includes("BE")
                  ? formatUen(item.billingCompanyNumber)
                  : formatUen("BE" + item.billingCompanyNumber);
                setUen(formattedUen);
              }
            }
          })
          .catch((e) => {})
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [isOpen, memberShipOrder, step]);

  const handleCloseModal = () => {
    closeModal();
    setStep("AUTH");
    if (showTerminate) {
      let memberShipType = "INDIVIDUAL";
      myMemberShips.forEach((item) => {
        if (item.membersShip.type === "FIDUCIARY") {
          memberShipType = "FIDUCIARY";
        }
      });
      setShowInfoBar(true);
      dispatch(setOrderType(memberShipType));
      dispatch(setIsSuccessPayment(true));
      setShowTerminate(false);
    }
  };

  const handleCheckboxClick = (e) => {
    e.stopPropagation();
    setIsAccepted(!isAccepted);
  };

  const handlePrivacyClick = (e) => {
    e.stopPropagation();
    setIsPrivacyAccepted(!isPrivacyAccepted);
  };

  const handleVatClick = (e) => {
    e.stopPropagation();
    setSubjectToVAT(!subjectToVAT);
  };

  const handleUenKeyUp = (value) => {
    let error = "";
    if (!validateUen(value)) {
      error = _("validate_uen");
    } else {
      error = "";
    }
    if (value.length === 0) {
      error = _("required_field");
    }
    setErrors({ ...errors, uen: error });
  };

  const isValidForm = () => {
    setErrors({
      ...errors,
      uen: !validateUen(uen) && subjectToVAT ? _("validate_uen") : "",
      organization: organization.length === 0 ? _("required_field") : "",
      address: address.length === 0 ? _("required_field") : "",
      postalCode: postalCode.length === 0 ? _("required_field") : "",
      city: city.length === 0 ? _("required_field") : "",
      approval: !isAccepted,
      privacy: !isPrivacyAccepted,
    });

    return (
      !errors.uen &&
      !errors.organization &&
      !errors.address &&
      !errors.postalCode &&
      !errors.city &&
      isAccepted &&
      isPrivacyAccepted
    );
  };

  const saveStep1 = () => {
    if (!isValidForm()) {
      return null;
    }

    const data = myMemberShips.map((item) => {
      return {
        formula: item.formula.id,
        membersShip: item.membersShip.id,
        sliderValue: item.membersShip.sliderValue,
      };
    });

    setIsLoading(true);
    let memberShip = JSON.stringify(data);
    addOrder({
      token,
      language,
      memberShip,
      uen,
      organization,
      address,
      postalCode,
      city,
      subjectToVAT,
      memberShipOrder,
      backUrl,
    })
      .then((response) => {
        if (response.data.data) {
          setOrder(response.data.data);
          setShowIframe(true);
          setStep("PAYMENT");
          setTimeout(() => setIsLoading(false), 800);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(_("error_add_order"));
      });
    // setTimeout(() => {
    //   setOrder({ id: 14246 });
    //   setShowIframe(true);
    //   setStep("PAYMENT");
    //   setTimeout(() => setIsLoading(false), 800);
    // }, 2000);
  };

  const handleAddressChange = (address) => {
    if (address.length === 0) {
      setErrors({ ...errors, address: _("required_field") });
    } else {
      setErrors({ ...errors, address: "" });
    }
    // setData({ ...data, address: address });
    setAddress(address);
  };

  const handleSelectAddress = (address) => {
    geocodeByAddress(address)
      .then((results) => results[0])
      .then((results) => {
        const addressComponents = results.address_components;
        const zip_code = addressComponents.filter((address) =>
          address.types.includes("postal_code")
        );
        const comp_city = addressComponents.filter(
          (address) =>
            address.types.includes("political") &&
            address.types.includes("locality")
        );
        const state = addressComponents.filter(
          (address) =>
            address.types.includes("political") &&
            address.types.includes("administrative_area_level_1")
        );
        const country = addressComponents.filter(
          (address) =>
            address.types.includes("political") &&
            address.types.includes("country")
        );
        const city = comp_city.length > 0 ? comp_city[0].long_name : "";
        const zipCode = zip_code.length > 0 ? zip_code[0].long_name : "";
        let cleanAdd = address;
        if (country.length > 0)
          cleanAdd = cleanAdd.replace(", " + country[0].long_name, "");
        if (state.length > 0)
          cleanAdd = cleanAdd.replace(", " + state[0].long_name, "");
        if (city.length > 0) cleanAdd = cleanAdd.replace(", " + city, "");

        setCity(city);
        setPostalCode(zipCode);
        setAddress(cleanAdd);

        setErrors({
          ...errors,
          postalCode: zipCode ? "" : _("required_field"),
          city: city ? "" : _("required_field"),
        });
      })
      .catch((error) => console.error("Error", error));
  };

  const handleShowNextStepAfterLogin = (newToken) => {
    if (newToken) {
      getUserMemberShip(newToken)
        .then((result) => {
          closeModal();
          toast.info(
            "Vous avez déjà un achat, vérifiez et continuer vers le paiement si tout est bon"
          );
        })
        .catch((e) => {
          setStep("USER_INFO");
        });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={false}
      style={customModalStyles}
      overlayClassName={styles.overlay}
      contentLabel="Tamtam payment"
    >
      <div className="rmodal">
        <div className={styles.close} onClick={handleCloseModal}>
          <i className="icon-ttp-close-outline"></i>
        </div>
        <div className={styles.wrapper}>
          {isLoading && <div className="lmask"></div>}
          <ul className={styles.steps}>
            <li
              className={`${styles.step} ${
                step === "AUTH" ? styles.step_active : ""
              }`}
            >
              <span>1</span> {_("authentication")}
            </li>
            <li
              className={`${styles.step} ${
                step === "USER_INFO" ? styles.step_active : ""
              }`}
            >
              <span>2</span> {_("billing")}
            </li>
            <li
              className={`${styles.step} ${
                step === "PAYMENT" ? styles.step_active : ""
              }`}
            >
              <span>3</span> {_("payment")}
            </li>
          </ul>

          {showTerminate && (
            <div className={styles.finish_btn}>
              <Button onClick={handleCloseModal}>{_("finish")}</Button>
            </div>
          )}

          {step === "AUTH" && (
            <AuthInfos
              showNextStepAfterLogin={handleShowNextStepAfterLogin}
              showNextStep={() => setStep("USER_INFO")}
            />
          )}

          {step === "USER_INFO" && (
            <>
              <div className="grid-x grid-margin-x">
                <div className="cell small-12 medium-6">
                  <FormInput
                    name="uen"
                    required={subjectToVAT}
                    placeholder="BE XXXX.XXX.XXX"
                    label={_("business_number")}
                    info={_("uen_info")}
                    error={errors.uen}
                    value={uen}
                    autoComplete="none"
                    // className="ttp-input-lg"
                    labelClassName="ttp-label-lg"
                    onKeyUp={(e) => handleUenKeyUp(e.target.value)}
                    //   handleBlur={(e) => handleUenBlur(e.target.value)}
                    onChange={(e) => setUen(e.target.value)}
                  ></FormInput>

                  <FormInput
                    name="organization"
                    required={true}
                    label={_("organization")}
                    autoComplete="off"
                    error={errors.organization}
                    value={organization}
                    // className="ttp-input-lg"
                    labelClassName="ttp-label-lg"
                    onChange={(e) => setOrganization(e.target.value)}
                  />
                  <div className={styles.vatbox}>
                    <div className={styles.acceptActions}>
                      <Checkbox
                        checked={subjectToVAT}
                        onClick={handleVatClick}
                        radio
                      />
                      <label
                        className={styles.checkbox}
                        onClick={handleVatClick}
                      >
                        {_("subject_to_vat")}
                      </label>
                    </div>
                    <div className={styles.acceptActions}>
                      <Checkbox
                        checked={!subjectToVAT}
                        onClick={handleVatClick}
                        radio={true}
                      />
                      <label
                        className={styles.checkbox}
                        onClick={handleVatClick}
                      >
                        {_("not_subject_to_vat")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="cell small-12 medium-6">
                  <div className="ttp-form-group-h">
                    <label className="ttp-label">
                      {_("address")}
                      <span className="star">*</span>
                    </label>
                    <PlacesAutocomplete
                      value={address}
                      onChange={handleAddressChange}
                      onSelect={handleSelectAddress}
                      searchOptions={{
                        componentRestrictions: { country: ["be"] },
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className="group-input">
                          <input
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className: classnames(
                                "location-search-input",
                                errors.address ? "error" : ""
                              ),
                            })}
                            autoComplete="none"
                            name="address"
                            // onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                          />
                          <span className="ttp-error">{errors.address}</span>
                          {suggestions.length > 0 ? (
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          ) : null}
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                  <FormInput
                    name="postalCode"
                    required={true}
                    label={_("postalCode")}
                    autoComplete="off"
                    error={errors.postalCode}
                    value={postalCode}
                    // className="ttp-input-lg"
                    labelClassName="ttp-label-lg"
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                  <FormInput
                    name="city"
                    required={true}
                    label={_("city")}
                    autoComplete="off"
                    error={errors.city}
                    value={city}
                    // className="ttp-input-lg"
                    labelClassName="ttp-label-lg"
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
              </div>

              <div className={styles.modalActions}>
                <div className={styles.terms_actions}>
                  <div>
                    <div className={styles.acceptActions}>
                      <Checkbox
                        checked={isPrivacyAccepted}
                        onClick={handlePrivacyClick}
                      />
                      <label
                        className={styles.checkbox}
                        onClick={handlePrivacyClick}
                      >
                        J'accepte{" "}
                        <a
                          target="_blank"
                          href="/privacy"
                          className={styles.link}
                          onClick={(e) => e.stopPropagation()}
                        >
                          les conditions sur la vie privée
                        </a>
                      </label>
                    </div>
                    {errors.privacy && (
                      <p className={styles.acceptError}>
                        {_("must_accept_privacy")}
                      </p>
                    )}
                  </div>

                  <div>
                    <div className={styles.acceptActions}>
                      <Checkbox
                        checked={isAccepted}
                        onClick={handleCheckboxClick}
                      />
                      <label
                        className={styles.checkbox}
                        onClick={handleCheckboxClick}
                      >
                        J'ai lu et j'accepte les{" "}
                        <a
                          target="_blank"
                          href="/privacy/terms_of_use"
                          className={styles.link}
                          onClick={(e) => e.stopPropagation()}
                        >
                          conditions générales
                        </a>
                      </label>
                    </div>
                    {errors.approval && (
                      <p className={styles.acceptError}>
                        {_("must_accept_conditions")}
                      </p>
                    )}
                  </div>
                </div>

                <Button onClick={saveStep1}>{_("pay")}</Button>
              </div>
            </>
          )}
          {step === "PAYMENT" && showIframe && (
            <iframe
              id="modal-payment-iframe"
              title="payment"
              src={`${TTP_PAYMENT_URL}/paymentMethode?orderID=${order.id}&token=${token}&embedded=1`}
            ></iframe>
          )}
        </div>
      </div>
    </Modal>
  );
}
