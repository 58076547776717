import axios from "axios";

import { TTP_API_URL } from "../config";

export const saveMemberShip = async (token, data) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-membership`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("nameFr", data.nameFr);
  formData.append("nameNl", data.nameNl);
  formData.append("nameEn", data.nameEn);
  formData.append("descriptionFr", data.descriptionFr);
  formData.append("descriptionNl", data.descriptionNl);
  formData.append("descriptionEn", data.descriptionEn);
  formData.append("status", data.status);
  formData.append("hasSlider", data.hasSlider);
  formData.append("sliderLabelFr", data.sliderLabelFr);
  formData.append("sliderLabelNl", data.sliderLabelNl);
  formData.append("sliderLabelEn", data.sliderLabelEn);
  formData.append("sliderTextFr", data.sliderTextFr);
  formData.append("sliderTextNl", data.sliderTextNl);
  formData.append("sliderTextEn", data.sliderTextEn);
  formData.append("sliderMin", data.sliderMin);
  formData.append("sliderMax", data.sliderMax);
  formData.append("sliderStep", data.sliderStep);

  if (data.id) {
    formData.append("id", data.id);
  }
  return await axios.post(requestUrl, formData);
};

export const saveMemberShipSettings = async (token, data) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-membership/save-settings`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", data.uaMemberShip);
  if (data.formulaCategories.length > 0) {
    data.formulaCategories.forEach((item, i) => {
      if (item.id) {
        formData.append(`uaMemberShipFormulaCategory[${i}][id]`, item.id);
      }
      formData.append(
        `uaMemberShipFormulaCategory[${i}][uaMemberShipFormula]`,
        item.uaMemberShipFormula
      );
      formData.append(
        `uaMemberShipFormulaCategory[${i}][uaMemberShipCategory]`,
        item.uaMemberShipCategory
      );
    });
  }

  return await axios.post(requestUrl, formData);
};

export const getHomeMemberShips = (token) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-membership/home`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
    },
  });
};

export const getUserMemberShip = (token) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-membership/user`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
    },
  });
};

export const getLastBillingsInfos = (token) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-membership/last-billing-infos`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
    },
  });
};

export const getMemberShips = (token) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-membership`;

  // const sort = [
  //   {
  //     property: "nbFrom",
  //     dir: "desc",
  //   },
  // ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      // sort: JSON.stringify(sort),
      limit: 200,
    },
  });
};

export const getMemberShip = (token, id) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-membership`;

  const filter = [
    {
      property: "id",
      value: id,
      operator: "eq",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: "*,formulas,appsList",
      filter: JSON.stringify(filter),
    },
  });
};

export const deleteMemberShip = (token, id) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-membership/${id}`;
  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const saveMemberShipFormula = async (token, data) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-membership-formula`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("nameFr", data.nameFr);
  formData.append("nameNl", data.nameNl);
  formData.append("nameEn", data.nameEn);
  formData.append("annualPrice", data.annualPrice);
  formData.append("uaMemberShip", data.uaMemberShip);
  formData.append("status", data.status);
  formData.append("annualStepPrice", data.annualStepPrice);

  if (data.id) {
    formData.append("id", data.id);
  }
  return await axios.post(requestUrl, formData);
};

export const deleteMemberShipFormula = (token, id) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-membership-formula/${id}`;
  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const saveMemberShipCategory = async (token, data) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-membership-category`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("nameFr", data.nameFr);
  formData.append("nameNl", data.nameNl);
  formData.append("nameEn", data.nameEn);
  formData.append("descriptionFr", data.descriptionFr);
  formData.append("descriptionNl", data.descriptionNl);
  formData.append("descriptionEn", data.descriptionEn);
  formData.append("uaMemberShip", data.uaMemberShip);
  formData.append("type", data.type);
  if (data.id) {
    formData.append("id", data.id);
  }
  if (data.parent) {
    formData.append("parent", data.parent);
  }
  return await axios.post(requestUrl, formData);
};

export const deleteMemberShipCategory = (token, id) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-membership-category/${id}`;
  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const addOrder = ({
  token,
  language,
  memberShip,
  uen,
  organization,
  address,
  postalCode,
  city,
  subjectToVAT,
  memberShipOrder,
  backUrl,
}) => {
  const requestUrl = `${TTP_API_URL}/organization/add-membership-order`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("memberShip", memberShip);
  formData.append("language", language);
  formData.append("uen", uen);
  formData.append("organization", organization);
  formData.append("address", address);
  formData.append("postalCode", postalCode);
  formData.append("country", city);
  formData.append("billingSubjectToVAT", subjectToVAT ? 1 : 0);
  if (memberShipOrder) {
    formData.append("memberShipOrder", memberShipOrder.id);
  }
  if (backUrl) {
    formData.append("backUrl", backUrl);
  }

  return axios.post(requestUrl, formData);
};

export const getUserByEmail = (token, email) => {
  let fields = ["*", "email", "phone"];

  let requestUrl = `${TTP_API_URL}/organization/user`;

  const filter = [
    {
      property: "email",
      value: email,
      operator: "eq",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      fields: fields.join(","),
      workspace: "ua",
    },
  });
};

export const saveAssociateMember = async (token, data) => {
  let requestUrl = `${TTP_API_URL}/organization/ua-membership/save-associate-user`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("email[0][email]", data.email);
  formData.append("firstName", data.firstname);
  formData.append("lastName", data.lastname);

  if (data.phone) {
    formData.append("phone[0][number]", data.phone);
  }

  if (data.userID) {
    formData.append("id", data.userID);
  }

  if (data.emailId) {
    formData.append("email[0][id]", data.emailId);
  }

  if (data.phoneId) {
    formData.append("phone[0][id]", data.phoneId);
  }

  return axios.post(requestUrl, formData);
};

export const deleteAssociateMember = (token, id) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-membership/delete-associate-user/${id}`;
  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};
