const i18nNl = {
  continue: "Doorgaan",
  signup: "Registreer",
  signin: "Aanmelden",
  forgot_password: "Vergeten?",
  must_accept_conditions: "U moet onze servicevoorwaarden accepteren",
  already_member: "Al lid?",
  server_error: "Server onbereikbaar. Probeer het later opnieuw",
  invalid_credentials: "Ongeldige ID's",
  check_email_help_txt:
    "Voor eventuele vragen of suggesties, of als u een verbindingsprobleem ondervindt, staat het team van United Associates tot uw beschikking via het adres info@unitedassociates.be of telefonisch op 0473/229.313.",
  email_address: "E-mailadres",
  email: "E-mail",
  firstname: "Voornaam",
  lastname: "Achternaam",
  validate_email: "Geef alstublieft een geldig email adres",
  validate_password: "wachtwoord benodigd",
  required_field: "Dit veld is verplicht",
  required_2_characters: "Geef minimaal 2 tekens op",
  password_weak: "Het wachtwoord is zwak!",
  password_not_match: "Vul nogmaals dezelfde waarde in",
  password: "Wachtwoord",
  confirm_password: "Bevestig wachtwoord",
  check_your_email: "Controleer uw e-mailinbox om uw adres te valideren",
  already_sent_email:
    "We hebben al een bevestigingsmail naar uw inbox gestuurd.",
  resend_confirmation_email: "Bevestigingsmail opnieuw verzenden",
  create_account: "Account aanmaken",
  send: "Sturen",
  password_changed_succesfully: "Wachtwoord succesvol opgeslagen",
  reset_pwd_check_your_email:
    "Er is een bericht naar uw e-mailbox verzonden. Raadpleeg deze en volg de instructies.",
  successfully_saved: "Succesvol opgeslagen",
  complete_personal_infos: "Vul mijn persoonlijke account in",
  mobile_phone_number: "Mobiel telefoonnummer",
  email_validated_successfully: "E-mailadres succesvol gevalideerd",
  account_exist_login:
    "Dit account bestaat al in het Tamtam-universum:<br/><ul><li><small>- oFFFcouse</small></li><li><small>- United Associates</small></li> < li><small>- Forum For the Future</small></li></ul>Voer uw wachtwoord in",
  account_exist_pwd:
    "Dit account bestaat al in het Tamtam-universum:<br/><ul><li><small>- oFFFcouse</small></li><li><small>- United Associates</small></li> < li><small>- Forum For the Future</small></li></ul>Stel een wachtwoord in",
  validate: "Valideren",
  male: "Man",
  female: "Vrouw",
  gender: "Geslacht",
  language: "Taal",
  numeroAgreation: "Erkenningsnummer (economische beroepen)",
  itaa: "ITAA Administratiekantoor",
  ire_audit: "IRE-accountantskantoor",
  law_office: "Advocatenkantoor",
  notarial_office: "Notarieel kantoor",
  other: "Anders...",
  validate_phone: "Het telefoonnummer is ongeldig",
  uen_label_number: "Voer het nummer in",
  oldPassword_incorrect: "Oud wachtwoord onjuist",
  email_not_unique: "E-mail al in gebruik",
  cancel: "Annuleren",
  english: "English",
  french: "French",
  dutch: "Dutch",
  reset_password: "Stel een wachtwoord in",
  help: "FAQ / Hulp",
  formula: "Formule",
  select: "Selecteer",
  selected: "Geselecteerd",
  uen_info:
    "Uniek ondernemingsnummer, ook wel KBO of BCE genoemd. Wij gebruiken het formaat <span>BE XXXX.XXX.XXX</span>. Deze waarde wordt gebruikt om bedrijven op ons platform uniek te identificeren.",
  validate_uen: "Geef een geldig nummer op",
  business_number: "Zaken nummer",
  organization: "Organisatie",
  address: "Adres",
  city: "Stad",
  postalCode: "Postcode",
  select_related_formula:
    "Selecteer de formule %formula1% om u te kunnen registreren voor de formule %formula2%",
  add_related_formula: "Voeg de formule %formula% toe",
  email_link_expired: "Sorry, deze link is verlopen. Log alsjeblieft in!",
  oeccbb_formulas:
    "De formules die verband houden met uw lidmaatschap van de gemeenschap van de Orde (OECCBB)",
  activated: "Geactiveerd",
  summary: "Samenvatting",
  no_selected_formula: "Er is geen formule geselecteerd",
  select_formula_membership:
    "Selecteer minstens één abonnement om je lidmaatschap te activeren",
  year: "jaar",
  already_paid: "Al betaald",
  total_to_pay: "Totaal te betalen",
  proceed_payment: "Ga door naar betaling",
  billing: "Facturering",
  payment: "Betaling",
  subject_to_vat: "Onderworpen aan BTW",
  not_subject_to_vat: "Niet onderworpen aan BTW",
  pay: "Betalen",
  manage_memberships: "Lidmaatschappen beheren",
  memberships_list: "Lijst met lidmaatschapsformules",
  nothing_found: "Niets gevonden...",
  add: "Toevoegen",
  add_new_membership: "Voeg een nieuw lidmaatschap toe",
  htva: "Excl. btw",
  member_fee: "Lidgeld",
  finish: "Afronden",
  must_accept_privacy: "Je moet de Privacyvoorwaarden accepteren",
  delete_confirm: "Bevestiging verwijderen",
  text_delete_member: "Weet je zeker dat je deze bijdrager wilt verwijderen?",
  no: "No",
  yes: "Yes",
};
export default i18nNl;
