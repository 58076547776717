import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Outlet,
  useNavigate,
  useLocation,
  createSearchParams,
} from "react-router-dom";
import $ from "jquery";

import _ from "../../i18n";
import {
  setAuthUser,
  setAuthToken,
  setExpiresIn,
  setTokenCreatedAt,
  fetchAuthUser,
  setLanguage,
} from "../../store";
import {
  getAuthInfosFromCookie,
  authHasExpired,
  getCookie,
  setCookie,
  logout,
} from "../../utils";
import { APP_ENV } from "../../config";
import Header from "./Header";

const Auth = ({ children }) => {
  const auth = useSelector((state) => state.auth);
  const backUrl = useSelector((state) => state.params.backUrl);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleAuthUser = (user) => {
    dispatch(setAuthToken(user.token));
    dispatch(setExpiresIn(user.expiresIn));
    dispatch(setTokenCreatedAt(user.createdAt));
    dispatch(fetchAuthUser(user));
  };

  useEffect(() => {
    let authInfos = getAuthInfosFromCookie();

    if (!authInfos || authHasExpired(authInfos)) {
      dispatch(setAuthUser(null));

      let dtExpire = new Date();
      dtExpire.setTime(dtExpire.getTime() - 3600000 * 1000);

      setCookie(
        "ttp_auth_" + APP_ENV,
        "",
        dtExpire,
        "/",
        "unitedassociates.be"
      );
      setCookie(
        "ttp_community_" + APP_ENV,
        "",
        dtExpire,
        "/",
        "unitedassociates.be"
      );
    }

    const cookieLang = getCookie(`ttp_lang_${APP_ENV}`);
    if (cookieLang) {
      dispatch(setLanguage(cookieLang));
    } else {
      const lng = localStorage.getItem("lng") || "fr";
      dispatch(setLanguage(lng));
    }

    if (authInfos) {
      handleAuthUser(authInfos);
    }

    if (location.pathname !== "/") {
      $("#app-loader")
        .fadeOut()
        .promise()
        .done(() => $("#app-loader").remove());
    }
  }, []);

  useEffect(() => {
    if (auth.user) {
      if (backUrl) {
        navigate({
          pathname: "/offers",
          search: createSearchParams({ back_url: backUrl }).toString(),
        });
      } else {
        navigate("/offers");
      }
    }
  }, [auth]);

  if (auth.user) {
    return null;
  }

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

export default Auth;
