import React, { useState } from "react";

import _ from "../../../i18n";

import Stepper from "./Stepper";
import MemberShipForm from "./MemberShipForm";
import styles from "./Sidebar.module.scss";
import FormulaMemberShip from "./FormulaMemberShip";
import MemberShipCategory from "./MemberShipCategory";
import FormulaMemberShipCategory from "./FormulaMemberShipCategory";

const Sidebar = ({
  isOpened,
  hideSidebar,
  currentMemberShip,
  setCurrentMemberShip,
}) => {
  const [step, setStep] = useState(1);

  const handleClose = () => {
    hideSidebar();
    setStep(1);
  };

  const handleSavePack = (p) => {
    setCurrentMemberShip(p);
    setStep(2);
  };

  return (
    <>
      <div className={`${styles.mask} ${isOpened ? "" : styles.hide}`}></div>
      <div className={`${styles.sidebar} ${isOpened && styles.display}`}>
        <div onClick={handleClose} className={styles.close}>
          <i className="icon-ttp-close"></i>
        </div>
        <div className={styles.title}>
          {currentMemberShip ? currentMemberShip.name : _("add_new_membership")}
        </div>

        <Stepper
          steps={["Cotisations", "Formules", "Catégories", "Paramétrage"]}
          currentStep={step}
          setStep={setStep}
          isClickabled={currentMemberShip ? true : false}
        />

        <div className={styles.content}>
          {step === 1 && (
            <MemberShipForm
              currentMemberShip={currentMemberShip}
              onCancel={handleClose}
              onSave={handleSavePack}
            />
          )}
          {step === 2 && (
            <FormulaMemberShip
              currentMemberShip={currentMemberShip}
              onBack={() => setStep(1)}
              onSave={() => setStep(3)}
            />
          )}
          {step === 3 && (
            <MemberShipCategory
              currentMemberShip={currentMemberShip}
              onBack={() => setStep(2)}
              onNext={() => setStep(4)}
            />
          )}
          {step === 4 && (
            <FormulaMemberShipCategory
              currentMemberShip={currentMemberShip}
              onBack={() => setStep(3)}
              onSave={handleClose}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
