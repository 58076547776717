import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { toast } from "react-toastify";

import FormInput from "../common/FormInput";
import Button from "../common/Button";
import Loader from "../common/Loader";
import _ from "../../i18n";
import { postUserCredential, changePassword } from "../../api";
import { validatePassword } from "../../utils";
import { setAuthTokenUser, fetchClientToken } from "../../store";

import styles from "./Login.module.scss";

const ResetPassword = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [emailKey, setEmailKey] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [pwdProgressValue, setPwdProgressValue] = useState(0);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");

    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === "email") {
        setEmail(decodeURIComponent(pair[1]));
      }
      if (pair[0] === "key") {
        setEmailKey(decodeURIComponent(pair[1]));
      }
      if (pair[0] === "acceptInvitationPwd") {
        toast.success(_("invitation_pwd_successfully"));
      }
    }

    if (!auth.clientToken) {
      dispatch(fetchClientToken());
    }
  }, []);

  const validate = () => {
    let success = true;

    if (password.length <= 0) {
      setErrors({ ...errors, password: _("validate_password") });
      success = false;
    }
    return success;
  };

  const handleResetPassword = () => {
    setIsSaving(true);
    changePassword({
      token: auth.clientToken,
      key: emailKey,
      password,
      passwordConfirm,
    })
      .then((resp) => {
        toast.success(_("password_changed_succesfully"));
        postUserCredential({
          email,
          password,
        }).then((authResponse) => {
          dispatch(setAuthTokenUser(authResponse.data));
          navigate("/");
        });
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errors) {
          e.response.data.errors.length > 0
            ? toast.error(_(e.response.data.errors[0].message))
            : e.response.data.errors.message
            ? toast.error(_(e.response.data.errors.message))
            : e.response?.status >= 500
            ? toast.error(_("server_error"))
            : toast.error(_("error_try_again"));
        } else {
          if (e?.response?.status === 502 || e?.response?.status === 503) {
            toast.error(_("server_error"));
          } else if (e?.response?.status >= 500 && e?.response?.data?.detail) {
            toast.error(e.response.data.detail);
          } else {
            toast.error(_("error_try_again"));
          }
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleLogin = () => {
    if (!validate()) {
      return null;
    }

    let data = {
      email,
      password,
    };

    setIsSaving(true);
    postUserCredential(data)
      .then((resp) => {
        dispatch(setAuthTokenUser(resp.data));
        navigate("/");
        setIsSaving(false);
      })
      .catch((e) => {
        toast.error(_("invalid_credentials"));
        setIsSaving(false);
      });
  };

  const handleKeyUp = (fieldName, fieldValue) => {
    let value = "";
    if (fieldValue.length === 0) {
      value = _("required_field");
    } else {
      switch (fieldName) {
        case "password":
          var pwdResult = validatePassword(password, [email]);
          if (pwdResult.strength > 30) {
            setPwdProgressValue(pwdResult.strength);
          } else {
            value = _("password_weak");
            if (pwdResult.strength < 4) {
              setPwdProgressValue(pwdResult.strength + 25);
            } else {
              setPwdProgressValue(pwdResult.strength - 0.01);
            }
          }
          break;
        case "passwordConfirm":
          if (fieldValue !== password) {
            value = _("password_not_match");
          }
          break;
        default:
      }
    }
    setErrors({ ...errors, [fieldName]: value });
    return value;
  };

  const formIsValid = () => {
    return (
      password.length > 0 &&
      errors.password === "" &&
      passwordConfirm.length > 0 &&
      errors.passwordConfirm === ""
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.rightSide}>
        <div className={styles.content}>
          <h1 className={styles.resetTitle}>{_("reset_password")}</h1>

          <div className={styles.emailInputBox}>
            <FormInput
              name="email"
              value={email}
              label={_("email_address")}
              disabled={true}
              className={styles.emailInput}
            />
            <i className="icon-ttp-check-outline"></i>
          </div>

          <div className={styles.passwordBox}>
            <FormInput
              name="password"
              value={password}
              label={_("password")}
              type="password"
              required={true}
              autocomplete="new-password"
              error={errors.password}
              onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
              onChange={(e) => setPassword(e.target.value)}
            />
            {password && (
              <progress
                className={`${pwdProgressValue > 30 ? "success" : "alert"} ${
                  styles.progress
                }`}
                max="100"
                value={pwdProgressValue}
              ></progress>
            )}
          </div>
          <FormInput
            name="passwordConfirm"
            value={passwordConfirm}
            label={_("confirm_password")}
            type="password"
            required={true}
            autoComplete="off"
            error={errors.passwordConfirm}
            onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />

          <div className={styles.actions}>
            {isSaving ? (
              <Button
                variant="primary"
                style={{ paddingTop: "15px", paddingBottom: "15px" }}
              >
                <Loader
                  style={{
                    height: "10px",
                  }}
                  color={"#fff"}
                />
              </Button>
            ) : (
              <Button disabled={!formIsValid()} onClick={handleResetPassword}>
                {_("reset_password")}
              </Button>
            )}
          </div>

          <div className={styles.footer}>
            <span
              onClick={() => navigate("/login")}
              className={styles.signinLink}
            >
              {_("signin")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
