import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import classnames from "classnames";

import {
  validateEmail,
  validatePhone,
  formatPhone,
  formatHidePhone,
} from "../../utils";
import { getUserByEmail, saveAssociateMember } from "../../api";
import _ from "../../i18n";
import FormInput from "../common/FormInput";
import Button from "../common/Button";
import Loader from "../common/Loader";

import styles from "./UserMemberModal.module.scss";

const customModalStyles = {
  content: {
    backgroundColor: "#fff",
    border: "none",
    overflow: "none",
    padding: 0,
    top: "50%",
    bottom: "unset",
    // height: "90%",
    maxHeight: "500px",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px rgba(41, 57, 77, 0.04)",
    zIndex: 999,
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    width: "80%",
    maxWidth: "600px",
  },
};

Modal.setAppElement("#root");

export default function UserMemberModal({
  isOpen,
  closeModal,
  currentItem,
  afterSave,
}) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [fetchedUser, setFetchedUser] = useState(null);
  const [disableFields, setDisableFields] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    lastname: "",
    firstname: "",
    phone: "",
    formula: "",
  });

  const handleCloseModal = () => {
    closeModal();
  };

  useEffect(() => {
    if (email.length > 0 && firstname.length > 0 && lastname.length > 0) {
      setIsValidForm(
        errors.email || errors.lastname || errors.firstname || errors.phone
          ? false
          : true
      );
    }
  }, [errors]);

  const handleKeyUp = (fieldName, fieldValue) => {
    let value = "";
    if (fieldValue.length === 0) {
      value = _("required_field");
    } else {
      switch (fieldName) {
        case "email":
          if (!validateEmail(fieldValue)) {
            value = _("validate_email");
          }
          break;
        case "phone":
          const result = validatePhone(fieldValue);
          if (!result.valid) {
            value = _("validate_phone");
          }
          break;
        case "lastname":
        case "firstname":
          if (fieldValue.length < 2) {
            value = _("required_2_characters");
          }
          break;
      }
    }
    setErrors({ ...errors, [fieldName]: value });
  };

  const handleEmailBlur = (e) => {
    if (email.length > 0 && validateEmail(email)) {
      if (fetchedUser && fetchedUser.mainEmail === email) {
        return null;
      }
      setIsFetchingUser(true);

      getUserByEmail(auth.token, email)
        .then((resp) => {
          if (resp.data.data.length > 0) {
            setFetchedUser(resp.data.data[0]);
            setFirstname(resp.data.data[0].firstName);
            setLastname(resp.data.data[0].lastName);
            if (resp.data.data[0].mainPhone) {
              setPhone(resp.data.data[0].mainPhone);
              setPhoneValue(formatHidePhone(resp.data.data[0].mainPhone));
            }
            setDisableFields(true);

            setErrors({
              email: "",
              lastname: "",
              firstname: "",
              phone: "",
            });
          }
          setIsFetchingUser(false);
          // validate();
        })
        .catch((e) => {
          setDisableFields(false);
          setIsFetchingUser(false);
          handleResetForm(false);
        });
    }
  };

  const handlePhoneBlur = (e) => {
    if (phone === phoneValue) {
      setPhone(formatPhone(phone));
      setPhoneValue(formatPhone(phone));
    }
  };

  const handlePhoneKeyUp = (fieldValue) => {
    let value = "";
    if (fieldValue.length > 0) {
      const result = validatePhone(fieldValue);
      if (!result.valid) {
        value = _("validate_phone");
      }
    }
    setErrors({ ...errors, phone: value });
  };

  const validate = () => {
    // let errors = [];
    handleKeyUp("email", email);
    handleKeyUp("lastname", lastname);
    handleKeyUp("firstname", firstname);
    handlePhoneKeyUp(phone);

    return errors.email || errors.lastname || errors.firstname || errors.phone
      ? true
      : false;
  };

  const save = () => {
    let error = validate();
    if (error) {
      return null;
    }

    const data = {
      email,
      firstname,
      lastname,
      phone,
    };

    // if (props.currentItem) {
    //   data.userID = props.currentItem.id;
    //   if (props.currentItem.email && props.currentItem.email.length > 0) {
    //     data.emailId = props.currentItem.email.filter(
    //       (item) => item.email === props.currentItem.mainEmail
    //     )[0].id;
    //   }
    //   if (props.currentItem.phone && props.currentItem.phone.length > 0) {
    //     data.phoneId = props.currentItem.phone.filter(
    //       (item) => item.number === props.currentItem.mainPhone
    //     )[0].id;
    //   }
    // }

    setIsSaving(true);
    saveAssociateMember(token, data)
      .then((resp) => {
        afterSave(resp.data.data);
        setTimeout(() => {
          setIsSaving(false);
          toast.success(_("successfully_added"));
          handleResetForm();
          handleCloseModal();
        }, 1000);
      })
      .catch((e) => {
        if (e?.response?.data?.errors) {
          const msg =
            e.response.data.errors?.length > 0
              ? _(e.response.data.errors[0].message)
              : e.response.data.errors?.message
              ? e.response.data.errors.message
              : _("error");
          toast.error(msg);
        } else {
          toast.error(_("error"));
        }
        setIsSaving(false);
      });
  };

  const handleResetForm = (resetEmail = true) => {
    if (resetEmail) {
      setEmail("");
    }
    setFirstname("");
    setLastname("");
    setPhone("");
    setPhoneValue("");
    setDisableFields(false);
    setFetchedUser(null);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={false}
      style={customModalStyles}
      overlayClassName={styles.overlay}
      contentLabel="Tamtam"
    >
      <div className="rmodal">
        <div className={styles.close} onClick={handleCloseModal}>
          <i className="icon-ttp-close-outline"></i>
        </div>
        <div
          className={`${styles.wrapper} ${isFetchingUser && styles.loading}`}
        >
          {isLoading && <div className="lmask"></div>}

          <div className={styles.titles}>
            <h4>Ajout d'un utilisateur</h4>
            <p>Remplissez les champs pour l'ajout</p>
          </div>

          <FormInput
            name="email"
            required={true}
            label={_("email_address")}
            autocomplete="none"
            error={errors.email}
            value={email}
            onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
            handleBlur={(e) => handleEmailBlur(e)}
            onChange={(e) => setEmail(e.target.value)}
            disabled={currentItem}
          />

          <div className="grid-x grid-margin-x">
            <div className="cell small-12 medium-6">
              <FormInput
                name="lastname"
                required={true}
                label={_("lastname")}
                autocomplete="none"
                error={errors.lastname}
                value={lastname}
                onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
                onChange={(e) => setLastname(e.target.value)}
                disabled={disableFields}
              />
            </div>
            <div className="cell small-12 medium-6">
              <FormInput
                name="firstname"
                required={true}
                label={_("firstname")}
                autocomplete="none"
                error={errors.firstname}
                value={firstname}
                onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
                onChange={(e) => setFirstname(e.target.value)}
                disabled={disableFields}
              />
            </div>
          </div>

          <FormInput
            name="phone"
            label={_("mobile_phone_number")}
            placeholder="+32XXXXXXXX"
            autocomplete="none"
            value={phoneValue}
            error={errors.phone}
            onKeyUp={(e) => handlePhoneKeyUp(e.target.value)}
            onChange={(e) => {
              setPhone(e.target.value);
              setPhoneValue(e.target.value);
            }}
            handleBlur={(e) => handlePhoneBlur(e)}
            disabled={disableFields && fetchedUser && fetchedUser.mainPhone}
          />

          <div className={styles.actions}>
            <Button
              variant="default"
              onClick={() => {
                handleResetForm();
                handleCloseModal();
              }}
            >
              {_("cancel")}
            </Button>
            {isSaving ? (
              <Button
                variant="primary"
                style={{ paddingTop: "15px", paddingBottom: "15px" }}
              >
                <Loader
                  style={{
                    height: "10px",
                  }}
                  color={"#fff"}
                />
              </Button>
            ) : (
              <Button disabled={!isValidForm} onClick={() => save()}>
                {currentItem ? _("edit") : _("add")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
