import _ from "../i18n";

export const APP_ENV = process.env.REACT_APP_APP_ENV;
export const TTP_API_URL = process.env.REACT_APP_TTP_API_URL;
export const TTP_ONBOARDING_URL = process.env.REACT_APP_TTP_ONBOARDING_URL;
export const TTP_HOME_URL = process.env.REACT_APP_TTP_HOME_URL;
export const TTP_PAYMENT_URL = process.env.REACT_APP_TTP_PAYMENT_URL;
export const TTP_UA_HOME = process.env.REACT_APP_TTP_UA_HOME_URL;
export const TTP_MEMBERSHIP_URL = process.env.REACT_APP_TTP_MEMBERSHIP_URL;
export const TTP_HELP_URL = process.env.REACT_APP_TTP_HELP_URL;

export const S3_FOLDER_URL = `https://s3.tamtam.pro/v2`;

export const UA_DOMAIN_NAME = `unitedassociates.be`;

export const AUTH_COOKIE_NAME = `ttp_auth_${APP_ENV}`;

export const API_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const GOOGLE_MAP_API_KEY = "AIzaSyAGh78WFs6n5bTGoDUv4XyiXY9mEWr1vY8";

export const DATA_BE_ID = "29358d21-5f7f-4b33-982b-950f6d234175";
export const DATA_BE_KEY = "54TRT8C-BXZMPCZ-K0NSA3W-DMHM2XC";
export const DATA_BE_URL = "https://api.data.be/2.0/companies";

export const URL_HASH_KEY = "Y3YZVA6uSLVqJhAWHdsb9CsGgcBXIhmY";

export const ARTICLE_LIMIT = 20;

export const PUBLIC_CLIENT_CREDENTIAL = {
  grant_type: "client_credentials",
  client_id: "11111",
  client_secret: "PublicSecret",
  scope: "public",
};

export const CLIENT_CREDENTIAL = {
  grant_type: "client_credentials",
  client_id: "10020",
  client_secret: "UASecrectS#K$",
  scope: "ttp",
};

export const LANGUAGES = [
  { value: "fr", label: _("french") },
  { value: "nl", label: _("dutch") },
  { value: "en", label: _("english") },
];

export const AGREATION_OPTIONS = [
  { value: "ITAA", label: _("itaa") },
  { value: "IRE_AUDIT", label: _("ire_audit") },
  { value: "LAW_OFFICE", label: _("law_office") },
  { value: "NOTARIAL_OFFICE", label: _("notarial_office") },
  { value: "UNIVERSITY", label: _("university") },
  { value: "OTHER", label: _("other") },
];
export const AGREATION_INPUT_SIZE = {
  FIDUCIARY: 8,
  ITAA: 8,
  IRE_AUDIT: 6,
  LAW_OFFICE: 8,
  NOTARIAL_OFFICE: 8,
  OTHER: 1,
};
export const AGREATION_INPUT_MASK = {
  FIDUCIARY: "99 . 999 . 999",
  ITAA: "99 . 999 . 999",
  IRE_AUDIT: "99 . 999 . 999",
  LAW_OFFICE: "99 . 999 . 999",
  NOTARIAL_OFFICE: "99 . 999 . 999",
  OTHER: "",
};

export const GENDER_OPTIONS = [
  { value: "MALE", label: _("male") },
  { value: "FEMALE", label: _("female") },
];

export const ALLOWED_APPS = [
  "BLOG",
  "FAQ",
  "DASHBOARD",
  "POWERTEAM",
  "WEBTOOLS",
  "OFFFCOURSE",
  "NEWSLETTER",
];

export const DEFAULT_APPS = ["OFFFCOURSE", "BLOG"];
export const ALLOWED_APPS_OPTIONS = [
  { name: "Watch", value: "OFFFCOURSE", icon: "offfcourse.svg" },
  { name: "Blog", value: "BLOG", icon: "blog.svg", type: "BOTH", relevance: 3 },
  { name: "Faq", value: "FAQ", icon: "faq.svg" },
  { name: "Dashboard", value: "DASHBOARD", icon: "dashboard.svg" },
  { name: "PowerTeam", value: "POWERTEAM", icon: "powerteam.svg" },
  { name: "E-News", value: "NEWSLETTER", icon: "sending.svg" },
  { name: "Webtools", value: "WEBTOOLS", icon: "webtools.svg" },
];
export const DEFAULT_TABS = ["COLLABORATOR", "CONTACT"];
export const ALLOWED_TABS_OPTIONS = [
  { name: "COLLABORATOR", value: "COLLABORATOR" },
  { name: "CLIENT", value: "CLIENT" },
  { name: "CONTACT", value: "CONTACT" },
];

export const PAID_APPS = ["FAQ", "DASHBOARD", "POWERTEAM", "WEBTOOLS"];

export const PAID_OPTIONS = ["SENDING"];

export const RATE_TYPES = ["COLLABORATOR", "APP", "OPTION"];

export const SELECT_STYLES = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? "#e6e6e6" : "#F8F9FA",
    boxShadow: "none",
    border: state.isFocused ? "1px solid #2495E1" : "1px solid #B2BCC6",
    "&:hover": {
      borderColor: state.isFocused ? "#18A0FB" : "#B2BCC6",
    },
    padding: 0,
    minHeight: "34px",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#6D7F92",
    fontWeight: 400,
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
    fontSize: "12px",
    lineHeight: "14px",
    "&::first-letter": {
      textTransform: "capitalize",
    },
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#F1F2F4",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontSize: ".75rem",
    "&::first-letter": {
      textTransform: "capitalize",
    },
    color: "inherit",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#29394D",
    "&::first-letter": {
      textTransform: "capitalize",
    },
  }),
  indicatorSeparator: (provided) => ({ display: "none" }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "4px",
  }),
};

export const SELECT_STYLES_LARGE = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? "#e6e6e6" : "#F8F9FA",
    boxShadow: "none",
    border: state.isFocused ? "1px solid #2495E1" : "1px solid #B2BCC6",
    "&:hover": {
      borderColor: state.isFocused ? "#18A0FB" : "#B2BCC6",
    },
    padding: 0,
    minHeight: "34px",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#6D7F92",
    fontWeight: 400,
    padding: "12px 8px",
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
    fontSize: "12px",
    lineHeight: "14px",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#F1F2F4",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontSize: ".75rem",
    textTransform: "uppercase",
    color: "inherit",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#29394D",
    padding: "11px 8px",
  }),
  indicatorSeparator: (provided) => ({ display: "none" }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "4px",
  }),
};

export const SELECT_INPUT_STYLES = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? "#e6e6e6" : "#F8F9FA",
    boxShadow: "none",
    border: 0,
    "&:hover": {
      borderColor: state.isFocused ? "#18A0FB" : "#B2BCC6",
    },
    padding: 0,
    minHeight: "34px",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#6D7F92",
    fontWeight: 400,
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
    fontSize: "12px",
    lineHeight: "14px",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#F1F2F4",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontSize: ".75rem",
    "&::first-letter": {
      textTransform: "capitalize",
    },
    color: "inherit",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#29394D",
  }),
  indicatorSeparator: (provided) => ({ display: "none" }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "4px",
  }),
};

export const COUNTRIES = {
  BE: "Belgique",
  FR: "France",
  NL: "Pays-Bas",
  GB: "Royaume-Uni",
  LU: "Luxembourg",
  DE: "Allemagne",
  IE: "Irlande",
  IT: "Italie",
  DK: "Danemark",
  FI: "Finlande",
  ES: "Espagne",
  GI: "Gibraltar",
  LI: "Liechtenstein",
  MC: "Monaco",
  NO: "Norvège",
  PT: "Portugal",
  SM: "San Marin",
  SE: "Suède",
  CH: "Suisse",
  VA: "Cité du Vatican",
  AD: "Andorre",
  AT: "Autriche",
};
export const TVA_TYPE = {
  VAT_SYSTEM_VAT: "Assujetti",
  VAT_SYSTEM_PARTIAL_VAT: "Assujetti partiel",
  VAT_SYSTEM_MIXED_VAT: "Assujetti mixte",
  VAT_SYSTEM_NO_VAT: "Non assujetti",
};

export const LEGAL_FORM = {
  SA: "Société Anonyme (SA)",
  SC: "Société Coopérative (SC)",
  SRL: "Société à Responsabilité Limitée (SRL)",
  SS: "Société Simple",
  SPRL: "Société Privée à Responsabilité Limitée (SPRL)",
  SPRI: "Société Privée à Responsabilité Illimitée (SPRI)",
  ASBL: "Association Sans But Lucratif (ASBL)",
  AISBL: "Association Internationale Sans But Lucratif (AISBL)",
  SCS: "Société en Commandite Simple (SCS)",
  SCA: "Société en Commandite par Actions (SCA)",
  SNC: "Société en Nom Collectif (SNC)",
  FOND: "Fondation",
  IP: "Indépendant principal",
  IC: "Indépendant complémentaire",
  AFS: "Autre forme de société",
};

export const DAYS = [
  { value: 1, label: _("monday") },
  { value: 2, label: _("tuesday") },
  { value: 3, label: _("wednesday") },
  { value: 4, label: _("thursday") },
  { value: 5, label: _("friday") },
  { value: 6, label: _("saturday") },
  { value: 7, label: _("sunday") },
];

export const NL_PLANS = [
  { value: "BASIC", label: _("basic") },
  { value: "EDITO", label: _("edito") },
  { value: "PERSONALIZED", label: _("personalized") },
  { value: "LITE", label: _("lite") },
];

export const SELECT_STYLES_NEWSLETTER = {
  container: (provided) => ({
    ...provided,
    pointerEvents: "all !important", // Add your desired container styles here
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? "#e6e6e6" : "#F8F9FA",
    boxShadow: "none",
    border: state.isFocused ? "1px solid #2495E1" : "1px solid #B2BCC6",
    "&:hover": {
      borderColor: state.isFocused ? "#18A0FB" : "#B2BCC6",
    },
    padding: 0,
    width: "200px",
    cursor: state.isDisabled ? "not-allowed !important" : "auto",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#6D7F92",
    fontWeight: 400,
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
    fontSize: "12px",
    lineHeight: "14px",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#F1F2F4",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontSize: ".75rem",
    "&::first-letter": {
      textTransform: "capitalize",
    },
    color: "inherit",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#29394D",
  }),
  indicatorSeparator: (provided) => ({ display: "none" }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "4px",
  }),
};
