import React from "react";

const IconCheckBadge = ({ size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0L11.7859 3.33511L15 1.33975L14.879 5.12096L18.6603 5L16.6649 8.21415L20 10L16.6649 11.7859L18.6603 15L14.879 14.879L15 18.6603L11.7859 16.6649L10 20L8.21415 16.6649L5 18.6603L5.12096 14.879L1.33975 15L3.33511 11.7859L0 10L3.33511 8.21415L1.33975 5L5.12096 5.12096L5 1.33975L8.21415 3.33511L10 0Z"
        fill="#02AF8E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.628 7.20016C13.7907 7.36288 13.7907 7.6267 13.628 7.78942L9.04463 12.3728C8.88191 12.5355 8.61809 12.5355 8.45537 12.3728L6.37204 10.2894C6.20932 10.1267 6.20932 9.86288 6.37204 9.70016C6.53476 9.53745 6.79858 9.53745 6.96129 9.70016L8.75 11.4889L13.0387 7.20016C13.2014 7.03745 13.4652 7.03745 13.628 7.20016Z"
        fill="white"
      />
    </svg>
  );
};

export default IconCheckBadge;
