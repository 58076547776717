import React from "react";

const IconYes = ({ size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_66836_43528)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.986 3.29112C11.5415 2.6475 9.92767 2.48805 8.38518 2.83656C6.84268 3.18506 5.45416 4.02285 4.42671 5.22497C3.39926 6.42709 2.78792 7.92913 2.68387 9.50708C2.57982 11.085 2.98863 12.6543 3.84934 13.981C4.71005 15.3076 5.97654 16.3204 7.45992 16.8685C8.94329 17.4165 10.5641 17.4704 12.0806 17.022C13.597 16.5736 14.928 15.647 15.8748 14.3805C16.8217 13.1139 17.3337 11.5752 17.3346 9.99378V9.22769C17.3346 8.6754 17.7824 8.22769 18.3346 8.22769C18.8869 8.22769 19.3346 8.6754 19.3346 9.22769V9.99435C19.3335 12.007 18.6818 13.966 17.4767 15.578C16.2716 17.19 14.5777 18.3692 12.6476 18.9399C10.7176 19.5106 8.65474 19.442 6.76681 18.7445C4.87887 18.047 3.26698 16.7579 2.17153 15.0695C1.07608 13.3811 0.555774 11.3838 0.6882 9.37549C0.820627 7.36719 1.5987 5.4555 2.90636 3.92553C4.21403 2.39556 5.98124 1.32928 7.94441 0.885728C9.90759 0.442175 11.9616 0.645107 13.8 1.46426C14.3044 1.68904 14.5312 2.28021 14.3064 2.78469C14.0816 3.28916 13.4904 3.5159 12.986 3.29112Z"
          fill="#02AF8E"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.0401 2.62066C19.4308 3.01099 19.4311 3.64416 19.0408 4.03488L10.7075 12.3765C10.52 12.5642 10.2656 12.6697 10.0002 12.6698C9.73495 12.6699 9.48049 12.5645 9.29289 12.3769L6.79289 9.8769C6.40237 9.48637 6.40237 8.85321 6.79289 8.46268C7.18342 8.07216 7.81658 8.07216 8.20711 8.46268L9.99965 10.2552L17.6259 2.62137C18.0162 2.23065 18.6494 2.23034 19.0401 2.62066Z"
          fill="#02AF8E"
        />
      </g>
      <defs>
        <clipPath id="clip0_66836_43528">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export function YesLineIcon({ size = 24 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.7071 5.29289C21.0976 5.68342 21.0976 6.31658 20.7071 6.70711L9.70711 17.7071C9.31658 18.0976 8.68342 18.0976 8.29289 17.7071L3.29289 12.7071C2.90237 12.3166 2.90237 11.6834 3.29289 11.2929C3.68342 10.9024 4.31658 10.9024 4.70711 11.2929L9 15.5858L19.2929 5.29289C19.6834 4.90237 20.3166 4.90237 20.7071 5.29289Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function YesGreenLineIcon({ size = 24 }) {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z"
        fill="#02AF8E"
      />
    </svg>
  );
}

export default IconYes;
