import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ModalConfirm } from "tamtam-components";
import { toast } from "react-toastify";

import { getMemberShip, deleteMemberShipFormula } from "../../../../api";
import _ from "../../../../i18n";

import Button from "../../../common/Button";
import Loader from "../../../common/Loader";
import FormulaPopOver from "./FormulaPopOver";
import styles from "./FormulaMemberShip.module.scss";
import sidebarStyles from "../Sidebar.module.scss";

const FormulaMemberShip = ({ currentMemberShip, onBack, onSave }) => {
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const language = useSelector((state) => state.params.language);
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const [isLoading, setIsLoading] = useState(false);
  const [memberShip, setMemberShip] = useState(null);
  const [openPopOver, setOpenPopOver] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [currentFormula, setCurrentFormula] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  useEffect(() => {
    if (currentMemberShip) {
      setIsLoading(true);
      getMemberShip(token, currentMemberShip.id)
        .then((response) => {
          if (response?.data?.data?.length === 1) {
            setMemberShip(response.data.data[0]);
          }
        })
        .catch((e) => {})
        .finally(() => {
          setIsLoading(false);
          if (refetch) {
            setRefetch(false);
          }
        });
    }
  }, [currentMemberShip, refetch]);

  const handleDelete = () => {
    if (currentFormula) {
      setDeleting(true);
      try {
        deleteMemberShipFormula(token, currentFormula.id)
          .then((resp) => {
            toast.success(_("successfully_deleted"));
            setCurrentFormula(null);
            setRefetch(true);
            setIsOpenDeleteModal(false);
          })
          .catch((e) => {
            toast.error("Error");
            setDeleting(false);
          });
      } catch (e) {
        setDeleting(false);
      }
    }
  };

  return (
    <div className={styles.page}>
      {isLoading && <div className="lmask"></div>}
      {memberShip && (
        <>
          <div className="grid-x grid-margin-x grid-margin-y">
            {memberShip?.uaMemberShipFormulas?.map((formula) => (
              <div className="cell small-12 medium-6" key={formula.id}>
                <div className={styles.formula}>
                  <div className={styles.formula_top}>
                    <span>{_("formula")}</span> {formula[nameAttr]}
                    <span
                      className={`${styles.formula_status} ${
                        styles[formula.status.toLowerCase()]
                      }`}
                    >
                      {_(formula.status.toLowerCase())}
                    </span>
                    <div className={styles.formula_actions}>
                      <button
                        className={styles.btn}
                        onClick={() => {
                          setCurrentFormula(formula);
                          setOpenPopOver(true);
                        }}
                      >
                        <i className="icon-ttp-edit" />
                      </button>
                      {/* <button
                        className={`${styles.btn} ${styles.btn_red}`}
                        onClick={() => {
                          setCurrentFormula(formula);
                          setIsOpenDeleteModal(true);
                        }}
                      >
                        <i className="icon-ttp-trash" />
                      </button> */}
                    </div>
                  </div>

                  <div className={styles.formula_bottom}>
                    <div className={styles.formula_price}>
                      <p className={styles.price_label}>Annuel</p>
                      <div className={styles.price_box}>
                        <div className={styles.price}>
                          {formula.annualPrice}
                          <span>€ /an</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="cell small-12 medium-4 large-3">
              <div
                className={styles.empty}
                onClick={() => setOpenPopOver(true)}
              >
                <i className="icon-ttp-plus" /> {_("add")}
              </div>
            </div>
          </div>
          {openPopOver && (
            <FormulaPopOver
              closePopOver={() => {
                setOpenPopOver(false);
                setCurrentFormula(null);
                // queryClient.invalidateQueries("listPacks");
              }}
              onSave={() => {
                setRefetch(true);
                setOpenPopOver(false);
                setCurrentFormula(null);
              }}
              currentFormula={currentFormula}
              currentMemberShip={memberShip}
            />
          )}
          <ModalConfirm
            type="delete"
            isOpen={isOpenDeleteModal}
            onCancel={() => setIsOpenDeleteModal(false)}
            onConfirm={() => handleDelete()}
            inProcess={deleting}
            title={_("modal_delete_title")}
            text={_("modal_delete_text")}
            labelNo={_("no")}
            labelYes={_("yes")}
            labelError={_("error")}
          />
        </>
      )}
      <div className={sidebarStyles.actions}>
        <Button variant="default" onClick={onBack}>
          {_("back")}
        </Button>
        {isSaving ? (
          <Button
            variant="primary"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          </Button>
        ) : (
          <Button
            disabled={
              !memberShip?.uaMemberShipFormulas ||
              memberShip?.uaMemberShipFormulas?.length === 0
            }
            onClick={onSave}
          >
            {_("continue")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default FormulaMemberShip;
