import React, { useState } from "react";

import _ from "../../../i18n";
import Login from "./Login";
import Register from "./Register";
import ResetPassword from "./ResetPassword";
import styles from "./AuthInfos.module.scss";

const AuthInfos = ({
  showNextStep,
  showNextStepAfterLogin,
  initView = "REGISTER",
}) => {
  const [view, setView] = useState(initView); // LOGIN | REGISTER | RESET_PASSWORD
  const [email, setEmail] = useState("");
  const [resetParams, setResetParams] = useState(null);

  return (
    <div className={styles.auth_container}>
      {view === "LOGIN" && (
        <Login
          showRegister={() => setView("REGISTER")}
          showNextStep={showNextStepAfterLogin}
          showResetPassword={(e) => {
            setResetParams(e);
            setView("RESET_PASSWORD");
          }}
          initialEmail={email}
        />
      )}
      {view === "REGISTER" && (
        <Register
          showLogin={() => setView("LOGIN")}
          showLoginWithEmail={(e) => {
            setEmail(e);
            setView("LOGIN");
          }}
          showNextStep={showNextStep}
        />
      )}
      {view === "RESET_PASSWORD" && (
        <ResetPassword
          {...resetParams}
          showLogin={() => setView("LOGIN")}
          showNextStep={showNextStepAfterLogin}
        />
      )}
    </div>
  );
};

export default AuthInfos;
