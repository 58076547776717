import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import Layout from "./components/Layout";
import Auth from "./components/Layout/Auth";
import Admin from "./components/Layout/Admin";
import Login from "./components/Login";
import ResetPassword from "./components/Login/ResetPassword";
import Register from "./components/Register";
import Offers from "./components/Offers";
import PaymentSuccessRedirect from "./components/SuccessMemberShip/PaymentSuccessRedirect";
import HomeRedirect from "./components/Home/HomeRedirect";
import ManageMemberShips from "./components/ManageMemberShips";
import ExpiredLink from "./components/ExpiredLink";
import Privacy from "./components/Privacy";

import { setBackUrl } from "./store";
import Profile from "./components/Profile/index";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === "back_url") {
        dispatch(setBackUrl(decodeURIComponent(pair[1])));
      }
    }
  }, []);

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="offers" element={<Offers />} />
            <Route path="paymentSuccess" element={<PaymentSuccessRedirect />} />
            <Route path="privacy/*" element={<Privacy />} />
            <Route path="profile" element={<Profile />} />
          </Route>
          <Route path="/" element={<HomeRedirect />} />
          <Route element={<Auth />}>
            <Route path="login" element={<Login />} />
            <Route path="resetPassword" element={<ResetPassword />} />
            <Route path="register" element={<Register />} />
            <Route path="expiredLink" element={<ExpiredLink />} />
          </Route>
          <Route element={<Admin />}>
            <Route path="manage-memberships" element={<ManageMemberShips />} />
          </Route>
        </Routes>
        <ToastContainer autoClose={3000} />
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
