import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { toast } from "react-toastify";
import ReactCodeInput from "react-code-input";

import FormInput from "../../common/FormInput";
import Button from "../../common/Button";
import Loader from "../../common/Loader";
import _ from "../../../i18n";
import {
  postUserCredential,
  resetPassword,
  postValidateEmailCode,
} from "../../../api";
import { validateEmail, cleanEmail } from "../../../utils";
import { setAuthTokenUser, fetchClientToken } from "../../../store";

import styles from "./AuthInfos.module.scss";

const Login = ({
  showRegister,
  showNextStep,
  showResetPassword,
  initialEmail,
}) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [email, setEmail] = useState(initialEmail);
  const [password, setPassword] = useState("");
  const [showForgot, setShowForgot] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [isSaving, setIsSaving] = useState(false);
  const [showCheckEmail, setShowCheckEmail] = useState(false);
  const [keyValidation, setKeyValidation] = useState("");
  const [showCodeError, setShowCodeError] = useState(false);

  useEffect(() => {
    if (showForgot && auth.clientToken === "") {
      dispatch(fetchClientToken());
    }
  }, [showForgot]);

  useEffect(() => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");

    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === "email") {
        setEmail(decodeURIComponent(pair[1]));
      }
      if (pair[0] === "acceptInvitation") {
        toast.success(_("invitation_successfully"));
      }
    }
  }, []);

  const handleBlurEmail = (e) => {
    if (!validateEmail(cleanEmail(email))) {
      setErrors({ ...errors, email: _("validate_email") });
    } else {
      setErrors({ ...errors, email: "" });
    }
  };

  const handleBlurPwd = (e) => {
    if (e.target.value.length < 0) {
      setErrors({ ...errors, password: "Length error" });
    } else {
      setErrors({ ...errors, password: "" });
    }
  };

  const validate = () => {
    let success = true;
    if (!validateEmail(cleanEmail(email))) {
      setErrors({ ...errors, email: _("validate_email") });
      success = false;
    }
    if (password.length <= 0) {
      setErrors({ ...errors, password: _("validate_password") });
      success = false;
    }
    return success;
  };

  const validateEmailCode = () => {
    if (keyValidation.length < 6) {
      setShowCodeError(true);
      return null;
    }
    setShowCodeError(false);
    setIsSaving(true);
    const emailStr = cleanEmail(email);
    postValidateEmailCode({
      token: auth.clientToken,
      email: emailStr,
      key: keyValidation,
      source: "reset",
    })
      .then((resp) => {
        setIsSaving(false);
        showResetPassword({ emailKey: resp.data.data.key, email: emailStr });
      })
      .catch((e) => {
        setIsSaving(false);
      });
  };

  const handleResetPassword = () => {
    if (!validateEmail(cleanEmail(email))) {
      setErrors({ ...errors, email: _("validate_email") });
      return null;
    }

    setIsSaving(true);
    resetPassword(auth.clientToken, email)
      .then((response) => {
        setShowCheckEmail(true);
        setIsSaving(false);
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.message
        ) {
          toast.error(_(e.response.data.errors.message));
        } else {
          if (e?.response?.status >= 500) {
            toast.error(_("server_error"));
          } else {
            toast.error(_("error"));
          }
        }
        setIsSaving(false);
      });
  };

  const handleLogin = () => {
    if (!validate()) {
      return null;
    }

    let data = {
      email: cleanEmail(email),
      password,
    };

    setIsSaving(true);
    postUserCredential(data)
      .then((resp) => {
        dispatch(setAuthTokenUser(resp.data));
        showNextStep(resp.data.token.access_token);
        setIsSaving(false);
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          toast.error(_("invalid_credentials"));
        } else {
          if (e?.response?.status >= 500) {
            toast.error(_("server_error"));
          } else {
            toast.error(_("error"));
          }
        }
        setIsSaving(false);
      });
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.rightSide}>
          <div className={styles.topButtons}>
            <h3 className={styles.title}>{_("signin")}</h3>
            <div>
              <span>Pas un membre?</span>
              <span onClick={showRegister} className={styles.register}>
                {_("signup")}
              </span>
            </div>
          </div>

          <div className={styles.content}>
            {showCheckEmail ? (
              <>
                <p className={styles.successMsg}>
                  <span>{_("reset_pwd_check_your_email")}</span>
                </p>
                <div className={styles.codeBox}>
                  <p>Entrez le code reçu :</p>
                  <ReactCodeInput
                    type="number"
                    fields={6}
                    value={keyValidation}
                    onChange={(value) => setKeyValidation(value)}
                    className={styles.codeInput}
                    inputStyle={{
                      marginRight: "0.463rem",
                      width: "34px",
                      borderRadius: "4px",
                      fontSize: "14px",
                      height: "44px",
                      backgroundColor: "#F8F9FA",
                      border: "1px solid #B6BFC8",
                      textAlign: "center",
                    }}
                    autoFocus={false}
                  />
                  {showCodeError && (
                    <span className={styles.error}>
                      Veuillez saisir le code
                    </span>
                  )}
                </div>
                <div className={styles.actions}>
                  {isSaving ? (
                    <Button
                      variant="primary"
                      style={{ paddingTop: "15px", paddingBottom: "15px" }}
                    >
                      <Loader
                        style={{
                          height: "10px",
                        }}
                        color={"#fff"}
                      />
                    </Button>
                  ) : (
                    <Button onClick={validateEmailCode}>{_("send")}</Button>
                  )}
                </div>
                <div
                  className={`${styles.signinLink} ${styles.footer}`}
                  onClick={() => {
                    setShowForgot(false);
                    setShowCheckEmail(false);
                  }}
                >
                  {_("signin")}
                </div>
              </>
            ) : (
              <>
                <FormInput
                  name="email"
                  value={email}
                  label={_("email")}
                  error={errors.email}
                  className="ttp-input-lg"
                  labelClassName="ttp-label-lg"
                  autocomplete="username"
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={(e) => handleBlurEmail(e)}
                />

                {!showForgot ? (
                  <>
                    <div className={styles.group}>
                      <label
                        className={classNames(styles.flexLabel, "ttp-label-lg")}
                      >
                        <span>{_("password")}</span>

                        <span
                          className={styles.forgotLabel}
                          onClick={() => setShowForgot(true)}
                        >
                          {_("forgot_password")}
                        </span>
                      </label>
                      <input
                        className={classNames(
                          "ttp-input-lg",
                          styles.pwdInput,
                          errors.password ? styles.error : ""
                        )}
                        onChange={(e) => setPassword(e.target.value)}
                        onBlur={(e) => handleBlurPwd(e)}
                        type="password"
                        value={password}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleLogin();
                          }
                        }}
                      />
                      {errors.password && (
                        <span className={styles.error}>{errors.password}</span>
                      )}
                    </div>

                    <div className={styles.actions}>
                      {isSaving ? (
                        <Button
                          variant="primary"
                          style={{ paddingTop: "15px", paddingBottom: "15px" }}
                        >
                          <Loader
                            style={{
                              height: "10px",
                            }}
                            color={"#fff"}
                          />
                        </Button>
                      ) : (
                        <Button onClick={handleLogin}>{_("signin")}</Button>
                      )}
                    </div>
                  </>
                ) : (
                  <div className={styles.actions}>
                    {isSaving ? (
                      <Button
                        variant="primary"
                        style={{ paddingTop: "15px", paddingBottom: "15px" }}
                      >
                        <Loader
                          style={{
                            height: "10px",
                          }}
                          color={"#fff"}
                        />
                      </Button>
                    ) : (
                      <Button onClick={handleResetPassword}>{_("send")}</Button>
                    )}
                  </div>
                )}

                {!showForgot ? (
                  <>
                    <div className={styles.footer}>
                      <p>Nouveau sur United Associates ?</p>
                      <p>Commencez par vous inscrire.</p>
                    </div>
                  </>
                ) : (
                  <div
                    className={`${styles.signinLink} ${styles.footer}`}
                    onClick={() => {
                      setShowForgot(false);
                      setShowCheckEmail(false);
                    }}
                  >
                    {_("signin")}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
