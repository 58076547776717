import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";

import { saveMemberShip } from "../../../api";
import _ from "../../../i18n";
import { SELECT_STYLES } from "../../../config";

import Tabs from "../../common/Tabs";
import Loader from "../../common/Loader";
import Button from "../../common/Button";
import Switch from "../../common/Switch/Switch";

import styles from "./Sidebar.module.scss";

const STATUS_OPTIONS = [
  { value: "ENABLED", label: _("enabled") },
  { value: "DISABLED", label: _("disabled") },
];

const MemberShipForm = ({ currentMemberShip, onSave, onCancel }) => {
  const auth = useSelector((state) => state.auth);
  const { token } = auth;

  const [isSaving, setIsSaving] = useState(false);
  const [nameFr, setNameFr] = useState("");
  const [nameNl, setNameNl] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [descriptionFr, setDescriptionFr] = useState("");
  const [descriptionNl, setDescriptionNl] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [status, setStatus] = useState(STATUS_OPTIONS[0]);
  const [hasSlider, setHasSlider] = useState(false);
  const [sliderLabelFr, setSliderLabelFr] = useState("");
  const [sliderLabelNl, setSliderLabelNl] = useState("");
  const [sliderLabelEn, setSliderLabelEn] = useState("");
  const [sliderTextFr, setSliderTextFr] = useState("");
  const [sliderTextNl, setSliderTextNl] = useState("");
  const [sliderTextEn, setSliderTextEn] = useState("");
  const [sliderMin, setSliderMin] = useState("");
  const [sliderMax, setSliderMax] = useState("");
  const [sliderStep, setSliderStep] = useState("");

  useEffect(() => {
    if (currentMemberShip) {
      setNameFr(currentMemberShip.nameFr);
      setNameNl(currentMemberShip.nameNl);
      setNameEn(currentMemberShip.nameEn);
      setDescriptionFr(currentMemberShip.descriptionFr);
      setDescriptionNl(currentMemberShip.descriptionNl);
      setDescriptionEn(currentMemberShip.descriptionEn);
      setStatus(
        STATUS_OPTIONS.filter(
          (status) => status.value === currentMemberShip.status
        )[0]
      );
      setHasSlider(currentMemberShip.hasSlider ? true : false);
      setSliderLabelFr(currentMemberShip.sliderLabelFr ?? "");
      setSliderTextFr(currentMemberShip.sliderTextFr ?? "");
      setSliderLabelNl(currentMemberShip.sliderLabelNl ?? "");
      setSliderTextNl(currentMemberShip.sliderTextNl ?? "");
      setSliderLabelEn(currentMemberShip.sliderLabelEn ?? "");
      setSliderTextEn(currentMemberShip.sliderTextEn ?? "");
      setSliderMin(currentMemberShip.sliderMin);
      setSliderMax(currentMemberShip.sliderMax);
      setSliderStep(currentMemberShip.sliderStep);
    } else {
      setNameFr("");
      setNameNl("");
      setNameEn("");
      setDescriptionFr("");
      setDescriptionNl("");
      setDescriptionEn("");
      setStatus(STATUS_OPTIONS[0]);
      setHasSlider(false);
      setSliderLabelFr("");
      setSliderTextFr("");
      setSliderLabelNl("");
      setSliderTextNl("");
      setSliderLabelEn("");
      setSliderTextEn("");
      setSliderMin("");
      setSliderMax("");
      setSliderStep("");
    }
  }, [currentMemberShip]);

  const validate = () => {
    let errors = [];

    if (!nameFr || !nameNl || !nameEn) {
      errors.push(_("membership_name_required"));
    }

    if (hasSlider) {
      if (
        sliderLabelFr?.length < 3 ||
        sliderLabelNl?.length < 3 ||
        sliderLabelEn?.length < 3
      ) {
        errors.push(_("slider_label_required"));
      }
      if (!sliderMin || parseInt(sliderMin) === 0) {
        errors.push(_("slider_min_required"));
      }
      if (!sliderMax || parseInt(sliderMax) === 0) {
        errors.push(_("slider_max_required"));
      }
      if (!sliderStep || parseInt(sliderStep) === 0) {
        errors.push(_("slider_step_required"));
      }
    }

    return errors;
  };

  const save = async (e) => {
    let errors = validate();
    if (errors && errors.length > 0) {
      let ErrorsContainer = ({ errors }) => (
        <div>
          <span>{_("errors") + " :"}</span>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      );
      toast.error(<ErrorsContainer errors={errors} />);
      return;
    }

    const data = {
      nameFr,
      nameNl,
      nameEn,
      descriptionFr,
      descriptionNl,
      descriptionEn,
      status: status.value,
      hasSlider: hasSlider ? 1 : 0,
      sliderLabelFr,
      sliderTextFr,
      sliderLabelNl,
      sliderTextNl,
      sliderLabelEn,
      sliderTextEn,
      sliderMin,
      sliderMax,
      sliderStep,
    };

    if (currentMemberShip) {
      data.id = currentMemberShip.id;
    }

    setIsSaving(true);
    saveMemberShip(token, data)
      .then((response) => {
        setIsSaving(false);
        onSave(response.data.data);
        toast.success(_("successfully_saved"));
      })
      .catch((e) => {
        setIsSaving(false);
      });
  };

  return (
    <div>
      <div className={styles.formRow}>
        <div>
          <label className="ttp-label">{_("status")}</label>
          <Select
            styles={SELECT_STYLES}
            options={STATUS_OPTIONS}
            value={status}
            onChange={(e) => setStatus(e)}
          />
        </div>
      </div>

      <Tabs theme="wtheme">
        <div
          key={`langtabfr`}
          label={_("french")}
          icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/fr.png`}
        >
          <div>
            <label className="ttp-label">{_(`name`) + " FR"}</label>
            <input
              type="text"
              className="ttp-input"
              autocomplete="off"
              value={nameFr}
              onChange={(e) => setNameFr(e.target.value)}
            />
          </div>
          <div>
            <label className="ttp-label">{_(`description`) + " FR"}</label>
            <textarea
              rows="4"
              className="ttp-input"
              value={descriptionFr}
              onChange={(e) => setDescriptionFr(e.target.value)}
            />
          </div>
        </div>
        <div
          key={`langtabnl`}
          label={_("dutch")}
          icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/nl.png`}
        >
          <div>
            <label className="ttp-label">{_(`name`) + " NL"}</label>
            <input
              type="text"
              className="ttp-input"
              autocomplete="off"
              value={nameNl}
              onChange={(e) => setNameNl(e.target.value)}
            />
          </div>
          <div>
            <label className="ttp-label">{_(`description`) + " NL"}</label>
            <textarea
              rows="4"
              className="ttp-input"
              value={descriptionNl}
              onChange={(e) => setDescriptionNl(e.target.value)}
            />
          </div>
        </div>
        <div
          key={`langtaben`}
          label={_("english")}
          icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/en.png`}
        >
          <div>
            <label className="ttp-label">{_(`name`) + " EN"}</label>
            <input
              type="text"
              className="ttp-input"
              autocomplete="off"
              value={nameEn}
              onChange={(e) => setNameEn(e.target.value)}
            />
          </div>
          <div>
            <label className="ttp-label">{_(`description`) + " EN"}</label>
            <textarea
              rows="4"
              className="ttp-input"
              value={descriptionEn}
              onChange={(e) => setDescriptionEn(e.target.value)}
            />
          </div>
        </div>
      </Tabs>

      <div className={styles.slider}>
        <div className="d-flex">
          <label className="ttp-label">{_("has_slider")}</label>
          <Switch isChecked={hasSlider} onChange={(e) => setHasSlider(e)} />
        </div>

        {hasSlider && (
          <>
            <div className="grid-x grid-margin-x grid-margin-y">
              <div className="cell small-12 medium-4">
                <label className="ttp-label">{_("slider_min")}</label>
                <input
                  type="text"
                  className="ttp-input"
                  autocomplete="off"
                  value={sliderMin}
                  onChange={(e) => setSliderMin(e.target.value)}
                />
              </div>

              <div className="cell small-12 medium-4">
                <label className="ttp-label">{_("slider_max")}</label>
                <input
                  type="text"
                  className="ttp-input"
                  autocomplete="off"
                  value={sliderMax}
                  onChange={(e) => setSliderMax(e.target.value)}
                />
              </div>

              <div className="cell small-12 medium-4">
                <label className="ttp-label">{_("slider_step")}</label>
                <input
                  type="text"
                  className="ttp-input"
                  autocomplete="off"
                  value={sliderStep}
                  onChange={(e) => setSliderStep(e.target.value)}
                />
              </div>
            </div>
            <Tabs theme="wtheme">
              <div
                key={`langtabfr`}
                label={_("french")}
                icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/fr.png`}
              >
                <div>
                  <label className="ttp-label">
                    {_("slider_label") + " FR"}
                  </label>
                  <input
                    type="text"
                    className="ttp-input"
                    autocomplete="off"
                    value={sliderLabelFr}
                    onChange={(e) => setSliderLabelFr(e.target.value)}
                  />
                </div>

                <div>
                  <label className="ttp-label">
                    {_(`slider_text`) + " FR"}
                  </label>
                  <textarea
                    rows="4"
                    className="ttp-input"
                    value={sliderTextFr}
                    onChange={(e) => setSliderTextFr(e.target.value)}
                  />
                </div>
              </div>
              <div
                key={`langtabnl`}
                label={_("dutch")}
                icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/nl.png`}
              >
                <div>
                  <label className="ttp-label">
                    {_("slider_label") + " NL"}
                  </label>
                  <input
                    type="text"
                    className="ttp-input"
                    autocomplete="off"
                    value={sliderLabelNl}
                    onChange={(e) => setSliderLabelNl(e.target.value)}
                  />
                </div>

                <div>
                  <label className="ttp-label">
                    {_(`slider_text`) + " NL"}
                  </label>
                  <textarea
                    rows="4"
                    className="ttp-input"
                    value={sliderTextNl}
                    onChange={(e) => setSliderTextNl(e.target.value)}
                  />
                </div>
              </div>
              <div
                key={`langtaben`}
                label={_("english")}
                icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/en.png`}
              >
                <div>
                  <label className="ttp-label">
                    {_("slider_label") + " EN"}
                  </label>
                  <input
                    type="text"
                    className="ttp-input"
                    autocomplete="off"
                    value={sliderLabelEn}
                    onChange={(e) => setSliderLabelEn(e.target.value)}
                  />
                </div>

                <div>
                  <label className="ttp-label">
                    {_(`slider_text`) + " EN"}
                  </label>
                  <textarea
                    rows="4"
                    className="ttp-input"
                    value={sliderTextEn}
                    onChange={(e) => setSliderTextEn(e.target.value)}
                  />
                </div>
              </div>
            </Tabs>
          </>
        )}
      </div>

      <div className={styles.actions}>
        <Button variant="default" onClick={onCancel}>
          {_("cancel")}
        </Button>
        {isSaving ? (
          <Button
            variant="primary"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          </Button>
        ) : (
          <Button onClick={save}>{_("save_and_continue")}</Button>
        )}
      </div>
    </div>
  );
};

export default MemberShipForm;
