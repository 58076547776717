import React from "react";

export const IconTrash = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8363 2.0148H9.92352V1.53988C9.92352 0.690789 9.23273 0 8.38363 0H5.61472C4.76562 0 4.07484 0.690789 4.07484 1.53988V2.0148H1.16201C0.946135 2.0148 0.773438 2.1875 0.773438 2.40337C0.773438 2.61924 0.946135 2.79194 1.16201 2.79194H1.86431V11.9219C1.86431 13.0674 2.79687 14 3.94243 14H10.0559C11.2015 14 12.134 13.0674 12.134 11.9219V2.79194H12.8363C13.0522 2.79194 13.2249 2.61924 13.2249 2.40337C13.2249 2.1875 13.0522 2.0148 12.8363 2.0148ZM4.85197 1.53988C4.85197 1.11965 5.19449 0.777138 5.61472 0.777138H8.38363C8.80386 0.777138 9.14638 1.11965 9.14638 1.53988V2.0148H4.85197V1.53988ZM11.3569 11.9219C11.3569 12.6386 10.7726 13.2229 10.0559 13.2229H3.94243C3.22574 13.2229 2.64145 12.6386 2.64145 11.9219V2.79194H11.3598V11.9219H11.3569Z"
      fill="currentColor"
    />
    <path
      d="M6.9994 11.8298C7.21527 11.8298 7.38796 11.6571 7.38796 11.4412V4.57363C7.38796 4.35776 7.21527 4.18506 6.9994 4.18506C6.78353 4.18506 6.61084 4.35776 6.61084 4.57363V11.4383C6.61084 11.6542 6.78353 11.8298 6.9994 11.8298Z"
      fill="currentColor"
    />
    <path
      d="M4.46425 11.401C4.68012 11.401 4.85282 11.2283 4.85282 11.0124V4.99965C4.85282 4.78378 4.68012 4.61108 4.46425 4.61108C4.24838 4.61108 4.07568 4.78378 4.07568 4.99965V11.0124C4.07568 11.2283 4.25126 11.401 4.46425 11.401Z"
      fill="currentColor"
    />
    <path
      d="M9.53554 11.401C9.75141 11.401 9.92411 11.2283 9.92411 11.0124V4.99965C9.92411 4.78378 9.75141 4.61108 9.53554 4.61108C9.31967 4.61108 9.14697 4.78378 9.14697 4.99965V11.0124C9.14697 11.2283 9.31967 11.401 9.53554 11.401Z"
      fill="currentColor"
    />
  </svg>
);
