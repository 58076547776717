import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import _ from "../../../../i18n";
import Button from "../../../common/Button";
import Loader from "../../../common/Loader";
import { savePackApp } from "../../../../api";

import styles from "./Accordion.module.scss";

const Accordion = ({
  currentPack,
  currentApp,
  setCurrentApp,
  openPopover,
  type,
  showDeleteModal,
}) => {
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const language = useSelector((state) => state.params.language);
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const [isSaving, setIsSaving] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [nameFr, setNameFr] = useState("");
  const [nameNl, setNameNl] = useState("");
  const [nameEn, setNameEn] = useState("");

  const validate = () => {
    let errors = [];

    if (!nameFr || !nameNl || !nameEn) {
      errors.push(_("app_name_required"));
    }

    return errors;
  };

  return (
    <div className={styles.accordion}>
      <div
        className={styles.accordion_title}
        onClick={() => setIsActive(!isActive)}
      >
        <div className={styles.accordion_name}>{currentApp[nameAttr]}</div>
        <div>
          <button
            className={styles.btn_action}
            onClick={(e) => {
              e.stopPropagation();
              openPopover(currentApp, "CATEGORY");
            }}
          >
            <i className="icon-ttp-pencil" />
          </button>
          <button
            className={styles.btn_action}
            onClick={(e) => {
              e.stopPropagation();
              openPopover(null, "SUB_CATEGORY", currentApp);
            }}
          >
            <i className="icon-ttp-plus" />
          </button>
          <button
            className={styles.btn_action}
            onClick={(e) => {
              e.stopPropagation();
              setCurrentApp(currentApp);
              showDeleteModal();
            }}
          >
            <i className="icon-ttp-trash" />
          </button>
          <span style={{ marginLeft: "20px" }}>
            {isActive ? (
              <i className="icon-ttp-chevron-up" />
            ) : (
              <i className="icon-ttp-chevron-down" />
            )}
          </span>
        </div>
      </div>
      {isActive && (
        <div className={styles.accordion_content}>
          {currentApp.children?.length > 0 ? (
            currentApp.children.map((subCategory) => (
              <details
                open
                className={`${styles.tree_item} ${styles.is_expandable}`}
              >
                <summary>
                  <span>{subCategory[nameAttr]}</span>
                  <div>
                    <button
                      className={styles.btn_action}
                      onClick={(e) => {
                        e.stopPropagation();
                        openPopover(subCategory, "SUB_CATEGORY", currentApp);
                      }}
                    >
                      <i className="icon-ttp-pencil" />
                    </button>
                    <button
                      className={styles.btn_action}
                      onClick={(e) => {
                        e.stopPropagation();
                        openPopover(null, "OPTION", subCategory);
                      }}
                    >
                      <i className="icon-ttp-plus" />
                    </button>
                    <button
                      className={styles.btn_action}
                      onClick={(e) => {
                        e.stopPropagation();
                        setCurrentApp(subCategory);
                        showDeleteModal();
                      }}
                    >
                      <i className="icon-ttp-trash" />
                    </button>
                  </div>
                </summary>
                {subCategory?.children?.length > 0 ? (
                  <>
                    <div className={styles.folder}>
                      {subCategory.children.map((option) => (
                        <div className={styles.tree_option}>
                          <span>{option[nameAttr]}</span>
                          <div>
                            <button
                              className={styles.btn_action}
                              onClick={(e) => {
                                e.stopPropagation();
                                openPopover(option, "OPTION", subCategory);
                              }}
                            >
                              <i className="icon-ttp-pencil" />
                            </button>
                            <button
                              className={styles.btn_action}
                              onClick={(e) => {
                                e.stopPropagation();
                                setCurrentApp(option);
                                showDeleteModal();
                              }}
                            >
                              <i className="icon-ttp-trash" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div className={`${styles.folder} ${styles.mandatory}`}>
                    Aucune options
                  </div>
                )}
              </details>
            ))
          ) : (
            <div className={`${styles.folder} ${styles.mandatory}`}>
              Aucune sous catégorie trouvée.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Accordion;
