import React from "react";
import CryptoJS from "crypto-js";

import styles from "./DrawerIframe.module.scss";
import { URL_HASH_KEY } from "../../config";

const encryptAES = (string) => {
  var encryptMethod = "AES-256-CBC";
  var aesNumber = encryptMethod.match(/\d+/)[0];
  let encryptMethodLength = parseInt(aesNumber);

  var iv = CryptoJS.lib.WordArray.random(16);
  var salt = CryptoJS.lib.WordArray.random(256);
  var iterations = 999;
  encryptMethodLength = encryptMethodLength / 4; // example: AES number is 256 / 4 = 64
  var hashKey = CryptoJS.PBKDF2(URL_HASH_KEY, salt, {
    hasher: CryptoJS.algo.SHA512,
    keySize: encryptMethodLength / 8,
    iterations: iterations,
  });

  var encrypted = CryptoJS.AES.encrypt(string, hashKey, {
    mode: CryptoJS.mode.CBC,
    iv: iv,
  });
  var encryptedString = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);

  var output = {
    ciphertext: encryptedString,
    iv: CryptoJS.enc.Hex.stringify(iv),
    salt: CryptoJS.enc.Hex.stringify(salt),
    iterations: iterations,
  };

  return CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(JSON.stringify(output))
  );
};

const DrawerIframe = (props) => {
  const { src, params, token, user } = props;

  if (src) {
    let param = [...params, "token=" + token];
    if (user) {
      param = [...params, "token=" + token, "userId=" + user.id];
    }
    const srcUrl =
      src.indexOf("?") === -1
        ? src + "?params=" + encodeURIComponent(encryptAES(param.join()))
        : src + "&params=" + encodeURIComponent(encryptAES(param.join()));

    return (
      <>
        <iframe
          className={styles.iframe}
          src={srcUrl}
          title="TAMTAM portal"
          onLoad={() => {
            if (document.getElementById("iframe-loader")) {
              {
                document.getElementById("iframe-loader").style.display = "none";
              }
            }
          }}
        />
      </>
    );
  } else {
    return <div style={{ minHeight: "70vh" }} />;
  }
};

export default DrawerIframe;
