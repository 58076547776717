import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ModalConfirm } from "tamtam-components";

import { getMemberShip, deleteMemberShipCategory } from "../../../../api";
import _ from "../../../../i18n";
import Button from "../../../common/Button";

import AppPopOver from "./AppPopOver";
import Accordion from "./Accordion";
import styles from "./Accordion.module.scss";
import sidebarStyles from "../Sidebar.module.scss";

const MemberShipCategory = ({ currentMemberShip, onBack, onNext }) => {
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const [isLoading, setIsLoading] = useState(false);
  const [memberShip, setMemberShip] = useState(null);
  const [openPopOver, setOpenPopOver] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [currentApp, setCurrentApp] = useState(null);
  const [appType, setAppType] = useState("APP");
  const [parentApp, setParentApp] = useState(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (currentMemberShip) {
      setIsLoading(true);
      getMemberShip(token, currentMemberShip.id)
        .then((response) => {
          if (response?.data?.data?.length === 1) {
            setMemberShip(response.data.data[0]);
          }
        })
        .catch((e) => {})
        .finally(() => {
          setIsLoading(false);
          if (refetch) {
            setRefetch(false);
          }
        });
    }
  }, [currentMemberShip, refetch]);

  const handleOpenPopover = (app, type, parentApp = null) => {
    setAppType(type);
    setCurrentApp(app);
    setParentApp(parentApp);
    setOpenPopOver(true);
  };

  const handleDelete = async () => {
    if (token) {
      try {
        setDeleting(true);
        await deleteMemberShipCategory(token, currentApp.id);
        setRefetch(true);
        setDeleting(false);
        setIsOpenDeleteModal(false);
        toast.success(_("successfully_deleted"));
      } catch (error) {
        setDeleting(false);
        toast.error(_("error_try_again"));
      }
    }
  };

  return (
    <div className={styles.page}>
      {isLoading && <div className="lmask"></div>}
      {memberShip && (
        <div className={styles.accordionBox}>
          {memberShip?.uaMemberShipCategories?.map((app) => (
            <Accordion
              key={app.id}
              currentApp={app}
              currentMemberShip={currentMemberShip}
              setCurrentApp={setCurrentApp}
              openPopover={handleOpenPopover}
              showDeleteModal={() => setIsOpenDeleteModal(true)}
            />
          ))}

          {memberShip?.uaMemberShipFormulas ? (
            <>
              <div
                className={styles.add_app_trigger}
                onClick={() => handleOpenPopover(null, "CATEGORY")}
              >
                {_("add_category")}
              </div>

              <div className={sidebarStyles.actions}>
                <Button variant="default" onClick={onBack}>
                  {_("back")}
                </Button>

                <Button
                  disabled={
                    !memberShip?.uaMemberShipCategories ||
                    memberShip?.uaMemberShipCategories.length === 0
                  }
                  onClick={onNext}
                >
                  {_("continue")}
                </Button>
              </div>
            </>
          ) : (
            <h3>Veuillez valider l'étape précédente</h3>
          )}

          {openPopOver && (
            <AppPopOver
              closePopOver={() => {
                setOpenPopOver(false);
                setCurrentApp(null);
              }}
              onSave={() => {
                setRefetch(true);
                setOpenPopOver(false);
                setCurrentApp(null);
              }}
              currentApp={currentApp}
              currentMemberShip={memberShip}
              type={appType}
              parentApp={parentApp}
            />
          )}

          <ModalConfirm
            type="delete"
            isOpen={isOpenDeleteModal}
            onCancel={() => setIsOpenDeleteModal(false)}
            onConfirm={() => handleDelete()}
            inProcess={deleting}
            title={_("modal_delete_title")}
            text={_("modal_delete_text")}
            labelNo={_("no")}
            labelYes={_("yes")}
            labelError={_("error")}
          />
        </div>
      )}
    </div>
  );
};

export default MemberShipCategory;
