import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AppendHead from "react-append-head";
import classNames from "classnames";

import { logout } from "../../utils";
import { APP_ENV } from "../../config";
import _ from "../../i18n";
import { setLanguage } from "../../store";
import AuthModal from "../AuthModal";
import MenuProfile from "./MenuProfile";
import TTPFaqWidget from "./TTPFaqWidget";
import styles from "./Header.module.scss";

const Header = () => {
  const lng = useSelector((state) => state.params.language);
  const isSuccessPayment = useSelector(
    (state) => state.params.isSuccessPayment
  );
  const backUrl = useSelector((state) => state.params.backUrl);
  const orderType = useSelector((state) => state.params.orderType);
  const auth = useSelector((state) => state.auth);
  const [showSettings, setShowSettings] = useState(false);
  const [settings, setSettings] = useState([]);
  const [isFaqWidgetLoaded, setIsFaqWidgetLoaded] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.user && (auth.user.isUaAdmin || auth.user.id === 462791)) {
      const tab = [];
      tab.push({
        label: _("manage_memberships"),
        url: "/manage-memberships",
      });
      setSettings(tab);
    }
  }, [auth]);

  const renderLeftSide = () => {
    return (
      <>
        <div className={styles.headerLeft}>
          <div
            className={`${styles.menuLogo} ${
              showSettings ? styles.shadow : ""
            }`}
          >
            {auth.user && settings.length > 0 && (
              <div>
                <span
                  className={`icon-ttp-three-dots ${styles.settingsIcon}`}
                  style={settings.length === 0 ? { visibility: "hidden" } : {}}
                  onClick={() => setShowSettings(!showSettings)}
                />
                <ul
                  className={`${styles.menuDropdown} ${
                    showSettings ? styles.show : ""
                  }`}
                >
                  {settings.map(({ label, url }) => (
                    <li key={url}>
                      <Link to={url}>{label}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <Link to={"/"} className={styles.appInfo}>
              <img
                className={styles.appLogo}
                src="/img/oeccbb.png"
                alt="oeccbb"
              />
            </Link>
          </div>
        </div>
      </>
    );
  };

  const onLogoutClick = () => {
    logout();
  };

  const changeLng = (language) => {
    dispatch(setLanguage(language));
    window.location.reload();
  };

  const renderLoggedIn = () => {
    const { user } = auth;

    let returnUrl = backUrl || "";
    if (orderType) {
      returnUrl += "?type=" + orderType;
    }

    return (
      <>
        <div className={styles.headerRight}>
          <ul className={`${styles.links}`}>
            {isSuccessPayment && backUrl && (
              <li>
                <a href={returnUrl} className={styles.backLink}>
                  <i className="icon-ttp-arrow-back"></i> Retour à l'application
                </a>
              </li>
            )}
            <li>
              <Link
                to={"/offers"}
                className={pathname === "/offers" && styles.active}
              >
                {_("member_fee")}
              </Link>
            </li>

            <li
              onClick={handleFaqClick}
              className={classNames(
                styles.icon,
                !isFaqWidgetLoaded && styles.iconLoading
              )}
            >
              <i className="icon-ttp-question-circle-o" />
            </li>
          </ul>

          <MenuProfile
            user={user}
            lng={lng}
            onLogoutClick={(e) => onLogoutClick(e)}
            onLanguageChange={(language) => changeLng(language)}
          />
        </div>

        {isFaqWidgetLoaded && (
          <TTPFaqWidget language={lng} auth={auth} faq isUA={true} />
        )}
      </>
    );
  };

  const renderLoggedOut = () => {
    return (
      <>
        <div className={styles.headerRight}>
          <ul className={`${styles.links} ${styles.signinLinks}`}>
            <li>
              <Link
                to={"/offers"}
                className={pathname === "/offers" && styles.active}
              >
                {_("member_fee")}
              </Link>
            </li>
            {/* <li>
            <Link
              to={"/login"}
              className={pathname === "/login" && styles.active}
            >
              {_("signin")}
            </Link>
          </li> */}
            <li>
              <AuthModal />
            </li>
            <li
              onClick={handleFaqClick}
              className={classNames(
                styles.icon,
                !isFaqWidgetLoaded && styles.iconLoading
              )}
            >
              <i className="icon-ttp-question-circle-o" />
            </li>
          </ul>
        </div>
        {isFaqWidgetLoaded && (
          <TTPFaqWidget
            language={lng}
            auth={{ token: auth.clientToken }}
            faq
            isUA={true}
          />
        )}
      </>
    );
  };

  const handleShowFaqWidget = () => {
    setTimeout(() => {
      setIsFaqWidgetLoaded(true);
    }, 4000);
  };

  const handleFaqClick = () => {
    if (window.showFAQ) {
      window.showFAQ("MEMBERSHIP");
    }
  };

  return (
    <>
      <AppendHead onLoad={handleShowFaqWidget}>
        <link
          name="faq-widget"
          rel="stylesheet"
          href={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/faq/${APP_ENV}/static/css/widget.css`}
        ></link>
        <script
          name="faq-widget-script"
          src={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/faq/${APP_ENV}/static/js/widget.js`}
        />
      </AppendHead>

      <header className={styles.header}>
        {renderLeftSide()} {auth.user ? renderLoggedIn() : renderLoggedOut()}
      </header>
    </>
  );
};

export default Header;
