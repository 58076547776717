import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { getMemberShip, saveMemberShipSettings } from "../../../../api";
import _ from "../../../../i18n";
import Button from "../../../common/Button";
import Loader from "../../../common/Loader";
import IconYes from "../../../common/icons/IconYes";
import IconNo from "../../../common/icons/IconNo";

import styles from "./FormulaMemberShipCategory.module.scss";
import sidebarStyles from "../Sidebar.module.scss";

const FormulaMemberShipCategory = ({ currentMemberShip, onBack, onSave }) => {
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const language = useSelector((state) => state.params.language);
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [memberShip, setMemberShip] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (currentMemberShip) {
      setIsLoading(true);
      getMemberShip(token, currentMemberShip.id)
        .then((response) => {
          if (response?.data?.data?.length === 1) {
            let tab = {};
            const result = response.data.data[0];
            if (result.uaMemberShipFormulas) {
              result?.uaMemberShipFormulas.forEach((formula) => {
                tab[formula.id] = formula.apps;
              });
            }
            setOptions(tab);
            setMemberShip(result);
          }
        })
        .catch((e) => {})
        .finally(() => {
          setIsLoading(false);
          if (refetch) {
            setRefetch(false);
          }
        });
    }
  }, [currentMemberShip, refetch]);

  const handleOptionClick = (formula, option) => {
    const tab = { ...options };
    if (tab[formula.id].includes(option.id)) {
      tab[formula.id] = tab[formula.id].filter((i) => i !== option.id);
    } else {
      tab[formula.id].push(option.id);
    }
    setOptions(tab);
  };

  const handleSave = () => {
    const formulaCategories = [];
    for (const formulaId in options) {
      if (options[formulaId].length > 0) {
        options[formulaId].forEach((option) => {
          formulaCategories.push({
            uaMemberShipFormula: parseInt(formulaId),
            uaMemberShipCategory: option,
          });
        });
      }
    }
    const data = {
      uaMemberShip: currentMemberShip.id,
      formulaCategories,
    };

    setIsSaving(true);
    saveMemberShipSettings(token, data)
      .then((response) => {
        setIsSaving(false);
        toast.success(_("successfully_saved"));
        onSave();
      })
      .catch((e) => {
        setIsSaving(false);
      });
  };

  return (
    <div className={styles.page}>
      {isLoading && <div className="lmask"></div>}
      {memberShip && (
        <>
          {memberShip.uaMemberShipFormulas && (
            <>
              <div className={`${styles.pack_empty_detail} ${styles.sticky}`}>
                <div className={styles.pack_empty_detail_1}>&nbsp;</div>
                {memberShip.uaMemberShipFormulas.map((formula) => (
                  <div className={styles.pack_empty_detail_2}>
                    {formula[nameAttr]}
                  </div>
                ))}
              </div>

              <div className={styles.pack_detail_bottom}>
                {memberShip.uaMemberShipCategories.map((category, idx) => (
                  <div key={idx}>
                    <div
                      className={`${styles.pack_app} ${
                        category.children.length === 0 && styles.pack_app_hover
                      }`}
                    >
                      <div
                        className={`${styles.pack_app_left} ${styles.pack_app_name}`}
                      >
                        {category[nameAttr]}
                      </div>
                      {memberShip.uaMemberShipFormulas.map((formula) =>
                        category.children.length === 0 ? (
                          <div
                            className={styles.pack_app_app}
                            onClick={() => handleOptionClick(formula, category)}
                          >
                            {options[formula.id].includes(category.id) ? (
                              <IconYes />
                            ) : (
                              <IconNo />
                            )}
                          </div>
                        ) : (
                          <div className={styles.pack_app_pro}></div>
                        )
                      )}
                    </div>

                    {category.children.map((subCategory, subcategoryIdx) => (
                      <div
                        key={subcategoryIdx}
                        className={styles.pack_app_category}
                      >
                        {category.children.length > 0 && (
                          <div
                            className={`${styles.pack_app} ${
                              subCategory.children.length === 0 &&
                              styles.pack_app_hover
                            }`}
                          >
                            <div
                              className={`${styles.pack_app_left} ${styles.pack_app_cat_left}`}
                            >
                              {subCategory[nameAttr]}
                            </div>
                            {memberShip.uaMemberShipFormulas.map((formula) =>
                              subCategory.children.length === 0 ? (
                                <div
                                  className={styles.pack_app_app}
                                  onClick={() =>
                                    handleOptionClick(formula, subCategory)
                                  }
                                >
                                  {options[formula.id].includes(
                                    subCategory.id
                                  ) ? (
                                    <IconYes />
                                  ) : (
                                    <IconNo />
                                  )}
                                </div>
                              ) : (
                                <div className={styles.pack_app_pro}></div>
                              )
                            )}
                          </div>
                        )}

                        {subCategory.children.map((option, optionIdx) => (
                          <div
                            key={optionIdx}
                            className={`${styles.pack_app} ${styles.pack_app_hover}`}
                          >
                            <div
                              className={`${styles.pack_app_left} ${styles.pack_app_fonct}`}
                            >
                              <span>{option[nameAttr]}</span>
                            </div>
                            {memberShip.uaMemberShipFormulas.map((formula) => {
                              if (!options[formula.id]) {
                                return null;
                              }
                              return (
                                <div
                                  className={styles.pack_app_app}
                                  onClick={() =>
                                    handleOptionClick(formula, option)
                                  }
                                >
                                  {options[formula.id].includes(option.id) ? (
                                    <IconYes />
                                  ) : (
                                    <IconNo />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              <div className={`${styles.pack_app} ${styles.pack_app_end}`}>
                <div className={styles.pack_app_left}></div>
                {memberShip.uaMemberShipFormulas.map((formula) => (
                  <div className={styles.pack_app_pro}></div>
                ))}
              </div>
            </>
          )}

          {memberShip?.uaMemberShipCategories.length > 0 ? (
            <div className={sidebarStyles.actions}>
              <Button variant="default" onClick={onBack}>
                {_("back")}
              </Button>

              {isSaving ? (
                <Button
                  variant="primary"
                  style={{ paddingTop: "15px", paddingBottom: "15px" }}
                >
                  <Loader
                    style={{
                      height: "10px",
                    }}
                    color={"#fff"}
                  />
                </Button>
              ) : (
                <Button onClick={handleSave}>{_("save")}</Button>
              )}
            </div>
          ) : (
            <h3 style={{ marginTop: "2rem" }}>
              Veuillez valider l'étape précédente
            </h3>
          )}
        </>
      )}
    </div>
  );
};

export default FormulaMemberShipCategory;
