import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProfileWidget from "./ProfileWidget";
import { APP_ENV } from "../../config";

const Profile = () => {
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);
  const auth = useSelector((state) => state.auth);
  const { token, user } = auth;
  const language = useSelector((state) => state.params.language);

  useEffect(() => {
    const script = document.createElement("script");
    if (user) {
      script.src = `https://tamtam.s3.eu-west-1.amazonaws.com/cdn/profile/${
        APP_ENV === "staging"
          ? "staging"
          : APP_ENV === "v2"
          ? "production"
          : APP_ENV
      }/static/js/profileWidget.js`;
      script.async = true;
      script.onload = () => {
        setIsWidgetLoaded(true);
      };

      document.body.appendChild(script);
    }

    return () => {
      // Cleanup if necessary
      if (user) {
        document.body.removeChild(script);
      }
    };
  }, [user]); // Empty dependency array to ensure the effect runs only once on component mount
  if (isWidgetLoaded) {
    return (
      <>
        <link
          href={`https://tamtam.s3.eu-west-1.amazonaws.com/cdn/profile/${
            APP_ENV === "staging"
              ? "staging"
              : APP_ENV === "v2"
              ? "production"
              : APP_ENV
          }/static/css/profileWidget.css`}
          rel="stylesheet"
        />
        <ProfileWidget token={token} lng={language} userId={user.id} />
      </>
    );
  }
  return null;
};

export default Profile;
