import axios from "axios";
import $ from "jquery";

import {
  TTP_API_URL,
  CLIENT_CREDENTIAL,
  PUBLIC_CLIENT_CREDENTIAL,
} from "../config";

export const getTTPUser = ({ userId, token }) => {
  const filter = [
    {
      property: "id",
      value: userId,
      operator: "eq",
    },
  ];

  const fields = [
    "*",
    "email",
    "phone",
    "birthday",
    "communities",
    "avatar",
    "description",
    "contactSocialNetworks",
    "cover",
    "url",
    "role",
    "roles",
    "blogRole",
    "userSettings",
    "numeroAgreation",
  ];

  const requestUrl = `${TTP_API_URL}/organization/user`;

  /*let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(",")
  };*/

  return $.ajax({
    type: "GET",
    async: false,
    url: requestUrl,
    data: `access_token=${token}&fields=${fields.join(
      ","
    )}&filter=${JSON.stringify(filter)}&workspace=ua`,
  });

  /*return axios.get(requestUrl, {
    //cancelToken: getLatestArticlesSourceToken.token,
    params
  });*/
};

export const getPublicClientCredential = () => {
  const requestUrl = `${TTP_API_URL}/token`;

  return $.ajax({
    type: "POST",
    async: false,
    url: requestUrl,
    data: PUBLIC_CLIENT_CREDENTIAL,
  });
};

export const getClientCredential = () => {
  const requestUrl = `${TTP_API_URL}/token`;

  return $.ajax({
    type: "POST",
    async: false,
    url: requestUrl,
    data: CLIENT_CREDENTIAL,
  });
};

export const postUserCredential = (data) => {
  const requestUrl = `${TTP_API_URL}/token`;

  var formData = new FormData();
  formData.append("username", data.email);
  formData.append("password", data.password);
  formData.append("grant_type", "password");
  formData.append("scope", "ttp");
  formData.append("client_id", CLIENT_CREDENTIAL.client_id);
  formData.append("client_secret", CLIENT_CREDENTIAL.client_secret);

  return axios.post(requestUrl, formData);
};

export const postUserEmail = (token, email, language, backUrl) => {
  const requestUrl = `${TTP_API_URL}/profile/email/ua-add`;

  var formData = new FormData();
  formData.append("email", email);
  formData.append("language", language);
  formData.append("access_token", token);
  formData.append("source", "REGISTER");
  formData.append("app", "MEMBERSHIP");
  if (backUrl) {
    formData.append("backUrl", backUrl);
  }

  return axios.post(requestUrl, formData);
};

export const postValidateEmailCode = ({
  token,
  id,
  email,
  key,
  source = "",
}) => {
  const requestUrl = `${TTP_API_URL}/profile/email/validate-code`;

  var formData = new FormData();
  formData.append("email", email);
  formData.append("key", key);
  formData.append("access_token", token);
  formData.append("id", id);
  if (source) {
    formData.append("source", source);
  }

  return axios.post(requestUrl, formData);
};

export const resetPassword = (token, email) => {
  let requestUrl = `${TTP_API_URL}/organization/user/resetPassword`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("email", email);
  formData.append("source", "membership");

  return axios.post(requestUrl, formData);
};

export const changePassword = ({ token, key, password, passwordConfirm }) => {
  let requestUrl = `${TTP_API_URL}/organization/user/newPassword`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("key", key);
  formData.append("password", password);
  formData.append("passwordConfirm", passwordConfirm);

  return axios.post(requestUrl, formData);
};

export const saveUser = (token, data) => {
  let requestUrl = `${TTP_API_URL}/organization/user`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("email[0][email]", data.email);
  formData.append("phone[0][number]", data.phone);
  formData.append("firstName", data.firstName);
  formData.append("lastName", data.lastName);
  formData.append("language", data.language);
  formData.append("gender", data.gender);
  if (data.password) formData.append("password", data.password);
  if (data.confirmPassword)
    formData.append("passwordConfirm", data.confirmPassword);
  if (data.agreation) formData.append("agreation", data.agreation);
  formData.append("isSecure", 1);
  formData.append("uaStatus", "VALIDATED");

  if (data.id) {
    formData.append("id", data.id);
  }

  if (data.role) {
    formData.append("role[0][type]", data.role.type);
    formData.append("role[0][organization]", data.role.organization);
  }

  if (data.organization_id) {
    formData.append("organization_id", data.organization_id);
  }

  return axios.post(requestUrl, formData);
};
