import { useNavigate } from "react-router-dom";

import Button from "../common/Button";
import _ from "../../i18n";

import styles from "./ExpiredLink.module.scss";

const ExpiredLink = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.rightSide}>
          <div className={styles.topButtons}>
            <span>Déjà membre?</span>
            <span
              onClick={() => navigate("/login")}
              className={styles.register}
            >
              S’identifier
            </span>
          </div>

          <div className={styles.content}>
            <h1 className={styles.title}>{_("signin")}</h1>

            <p className={styles.successMsg}>
              <br />

              <span>{_("email_link_expired")}</span>
            </p>

            <div className={styles.actions}>
              <Button onClick={() => navigate("/login")}>{_("signin")}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpiredLink;
