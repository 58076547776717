const i18nFr = {
  continue: "Continuer",
  signup: "S’inscrire",
  signin: "Se connecter",
  forgot_password: "Oublié?",
  must_accept_conditions: "Vous devez accepter nos conditions d'utilisation",
  already_member: "Déjà membre?",
  server_error: "Serveur inaccessible - Re-essayer plus tard",
  invalid_credentials: "Identifiants invalides",
  check_email_help_txt:
    "Pour toute question ou suggestion, ou si vous rencontrez un problème de connexion, l’équipe United Associates est à votre entière disposition via l’adresse info@unitedassociates.be ou par téléphone au 0473/229.313.",
  email_address: "Adresse E-mail",
  email: "E-mail",
  firstname: "Prénom",
  lastname: "Nom",
  validate_email: "Veuillez fournir une adresse électronique valide",
  validate_password: "Mot de passe obligatoire",
  required_field: "Ce champ est obligatoire",
  required_2_characters: "Veuillez fournir au moins 2 caractères",
  password_weak: "Le mot de passe est faible!",
  password_not_match: "Veuillez entrez à nouveau la même valeur",
  password: "Mot de passe",
  confirm_password: "Confirmez le mot de passe",
  check_your_email:
    "Veuillez vérifier votre boite email pour valider votre adresse",
  already_sent_email:
    "Nous avons déjà envoyer un email de confirmation dans votre boite.",
  resend_confirmation_email: "Réenvoyer un email de confirmation",
  create_account: "Créer un compte",
  send: "Envoyer",
  password_changed_succesfully: "Mot de passe enregistré avec succés",
  reset_pwd_check_your_email:
    "Un message a été envoyé à votre boîte e-mail. Veuillez le consulter et suivre les instructions.",
  successfully_saved: "Enregistré avec succès",
  complete_personal_infos: "Compléter mon compte personnel",
  mobile_phone_number: "Numéro Téléphone Mobile",
  email_validated_successfully: "Adresse email validée avec succès",
  account_exist_login:
    "Ce compte existe déjà dans l'univers Tamtam:<br/><ul><li><small>- oFFFcouse</small></li><li><small>- United Associates</small></li><li><small>- Forum For the Future</small></li></ul>Veuillez saisir votre mot de passe",
  account_exist_pwd:
    "Ce compte existe déjà dans l'univers Tamtam:<br/><ul><li><small>- oFFFcouse</small></li><li><small>- United Associates</small></li><li><small>- Forum For the Future</small></li></ul>Veuillez définir un mot de passe",
  validate: "Valider",
  male: "Homme",
  female: "Femme",
  gender: "Genre",
  language: "Langue",
  numeroAgreation: "Numéro d'agréation (professions économiques)",
  itaa: "Bureau fiduciaire ITAA",
  ire_audit: "Cabinet d’audit IRE",
  law_office: "Cabinet d’avocats",
  notarial_office: "Cabinet d’étude Notariale",
  other: "Autres...",
  validate_phone: "Le numéro de téléphone est invalide",
  uen_label_number: "Saisissez le numéro",
  oldPassword_incorrect: "Ancien mot de passe incorrect",
  email_not_unique: "Adresse e-mail déjà prise",
  cancel: "Annuler",
  english: "Anglais",
  french: "Français",
  dutch: "Néerlandais",
  reset_password: "Définir le mot de passe",
  help: "FAQ / Aide",
  formula: "Formule",
  select: "Sélectionner",
  selected: "Sélectionnée",
  uen_info:
    "Numéro unique d'entreprise, aussi connu sous l'appellation KBO ou BCE. Nous utilisons le format <span>BE XXXX.XXX.XXX</span>. Cette valeur est utilisée pour identifier les entreprises de manière unique sur notre plateforme.",
  validate_uen: "Veuillez fournir un numéro valide",
  business_number: "Numéro d'entreprise",
  organization: "Organisation",
  address: "Adresse",
  city: "Ville",
  postalCode: "Code postal",
  select_related_formula:
    "Veuillez sélectionnez la formule %formula1% pour pourvoir vous inscrire à la formule %formula2%",
  add_related_formula: "Ajouter la formule %formula%",
  email_link_expired: "Désolé, ce lien a expiré. Veuillez vous connecter!",
  oeccbb_formulas:
    "Les formules liées à votre adhésion à la communauté de l’ordre (OECCBB)",
  activated: "Activée",
  summary: "Résumé",
  no_selected_formula: "Aucune formule n’a été sélectionnée",
  select_formula_membership:
    "Séléctionnez au moins une formule pour activer votre adhésion",
  year: "an",
  already_paid: "Déjà payer",
  total_to_pay: "Total à payer",
  proceed_payment: "Passer au paiement",
  billing: "Facturation",
  payment: "Paiement",
  subject_to_vat: "Soumis à la TVA",
  not_subject_to_vat: "Non soumis à la TVA",
  pay: "Payer",
  manage_memberships: "Gérer les cotisations",
  memberships_list: "Liste des formules des cotisations",
  nothing_found: "Rien n'a été trouvé ...",
  add: "Ajouter",
  add_new_membership: "Ajouter une nouvelle cotisation",
  htva: "HTVA",
  member_fee: "Cotisation membre",
  finish: "Terminer",
  must_accept_privacy: "Vous devez accepter les Conditions sur la vie privée",
  authentication: "Authentification",
  delete_confirm: "Confirmation de suppression",
  text_delete_member: "Êtes-vous sûr de bien vouloir supprimer cet élément ?",
  no: "Non",
  yes: "Oui",
};
export default i18nFr;
