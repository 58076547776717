import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const HomeRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/offers" + window.location.search);
  }, []);

  return null;
};

export default HomeRedirect;
