import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import classNames from "classnames";
import Select from "react-select";

import { saveMemberShipFormula } from "../../../../api";
import _ from "../../../../i18n";
import { SELECT_STYLES } from "../../../../config";

import Loader from "../../../common/Loader";
import Button from "../../../common/Button";
import Tabs from "../../../common/Tabs";
import Switch from "../../../common/Switch/Switch";

import styles from "./FormulaPopOver.module.scss";

const STATUS_OPTIONS = [
  { value: "ENABLED", label: _("enabled") },
  { value: "DISABLED", label: _("disabled") },
];

const PackPopOver = ({
  currentFormula,
  currentMemberShip,
  closePopOver,
  onSave,
}) => {
  const auth = useSelector((state) => state.auth);
  const { token } = auth;

  const [isSaving, setIsSaving] = useState(false);
  const [status, setStatus] = useState(STATUS_OPTIONS[0]);
  const [nameFr, setNameFr] = useState("");
  const [nameNl, setNameNl] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [annualPrice, setAnnualPrice] = useState("");

  const [hasSlider, setHasSlider] = useState(false);
  const [sliderLabelFr, setSliderLabelFr] = useState("");
  const [sliderLabelNl, setSliderLabelNl] = useState("");
  const [sliderLabelEn, setSliderLabelEn] = useState("");
  const [sliderTextFr, setSliderTextFr] = useState("");
  const [sliderTextNl, setSliderTextNl] = useState("");
  const [sliderTextEn, setSliderTextEn] = useState("");
  const [sliderMin, setSliderMin] = useState("");
  const [sliderMax, setSliderMax] = useState("");
  const [sliderStep, setSliderStep] = useState("");
  const [annualStepPrice, setAnnualStepPrice] = useState("");

  useEffect(() => {
    if (currentFormula) {
      setNameFr(currentFormula.nameFr);
      setNameNl(currentFormula.nameNl);
      setNameEn(currentFormula.nameEn);
      setAnnualPrice(String(currentFormula.annualPrice));
      setAnnualStepPrice(String(currentFormula.annualStepPrice));

      setStatus(
        STATUS_OPTIONS.filter(
          (status) => status.value === currentFormula.status
        )[0]
      );
    }
  }, [currentFormula]);

  const validate = () => {
    let errors = [];

    if (!nameFr || !nameNl || !nameEn) {
      errors.push(_("formula_name_required"));
    }

    if (!annualPrice) {
      errors.push(_("annual_price_required"));
    }

    return errors;
  };

  const save = async (e) => {
    let errors = validate();
    if (errors && errors.length > 0) {
      let ErrorsContainer = ({ errors }) => (
        <div>
          <span>{_("errors") + " :"}</span>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      );
      toast.error(<ErrorsContainer errors={errors} />);
      return;
    }

    const data = {
      nameFr,
      nameNl,
      nameEn,
      annualPrice,
      annualStepPrice,
      uaMemberShip: currentMemberShip.id,
      status: status.value,
    };

    if (currentFormula) {
      data.id = currentFormula.id;
    }

    setIsSaving(true);
    saveMemberShipFormula(token, data)
      .then((response) => {
        setIsSaving(false);
        onSave();
        toast.success(_("successfully_saved"));
      })
      .catch((e) => {
        setIsSaving(false);
      });
  };

  const handleClose = () => {
    closePopOver();
  };

  return (
    <div>
      <div
        className={classNames(styles.popOver, styles.positioning)}
        onClick={(e) => e.stopPropagation()}
      >
        <div onClick={() => handleClose()} className={styles.close}>
          <i className="icon-ttp-close"></i>
        </div>

        <h3>{currentFormula ? "Modifier" : "Ajouter une formule"}</h3>

        <Tabs theme="wtheme">
          <div
            key={`langtabfr`}
            label={_("french")}
            icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/fr.png`}
          >
            <div>
              <label className="ttp-label">{_(`name`) + " FR"}</label>
              <input
                type="text"
                className="ttp-input"
                autocomplete="off"
                value={nameFr}
                onChange={(e) => setNameFr(e.target.value)}
              />
            </div>
          </div>
          <div
            key={`langtabnl`}
            label={_("dutch")}
            icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/nl.png`}
          >
            <div>
              <label className="ttp-label">{_(`name`) + " NL"}</label>
              <input
                type="text"
                className="ttp-input"
                autocomplete="off"
                value={nameNl}
                onChange={(e) => setNameNl(e.target.value)}
              />
            </div>
          </div>
          <div
            key={`langtaben`}
            label={_("english")}
            icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/en.png`}
          >
            <div>
              <label className="ttp-label">{_(`name`) + " EN"}</label>
              <input
                type="text"
                className="ttp-input"
                autocomplete="off"
                value={nameEn}
                onChange={(e) => setNameEn(e.target.value)}
              />
            </div>
          </div>
        </Tabs>

        <div className={styles.formRow}>
          <div>
            <label className="ttp-label">Prix annuel</label>
            <input
              type="text"
              className="ttp-input"
              autocomplete="off"
              value={annualPrice}
              onChange={(e) => setAnnualPrice(e.target.value)}
            />
          </div>
          <div>
            <label className="ttp-label">{_("status")}</label>
            <Select
              styles={SELECT_STYLES}
              options={STATUS_OPTIONS}
              value={status}
              onChange={(e) => setStatus(e)}
            />
          </div>
        </div>

        {currentMemberShip.hasSlider === 1 && (
          <>
            <h4 className={styles.slider_price}>Prix par itération slider</h4>

            <div className={styles.formRow}>
              <div>
                <label className="ttp-label">Prix annuel</label>
                <input
                  type="text"
                  className="ttp-input"
                  autocomplete="off"
                  value={annualStepPrice}
                  onChange={(e) => setAnnualStepPrice(e.target.value)}
                />
              </div>
            </div>
          </>
        )}

        <div className={styles.actions}>
          {isSaving ? (
            <Button
              variant="primary"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Loader
                style={{
                  height: "10px",
                }}
                color={"#fff"}
              />
            </Button>
          ) : (
            <Button onClick={save}>
              {currentFormula ? _("update") : _("add")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PackPopOver;
