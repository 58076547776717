import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import DrawerIframe from "./DrawerIframe";
import { TTP_HELP_URL } from "../../config";

const Privacy = () => {
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const { token, user } = auth;
  const language = useSelector((state) => state.params.language);

  const params = [];
  params.push("without_header=1");
  params.push("without_submenu=1");
  params.push("without_backnav=1");
  let faqUrl = TTP_HELP_URL + "/" + language;

  faqUrl += location.pathname + location.search;

  if (window) window.scrollTo({ top: 0, behavior: "smooth" });
  return <DrawerIframe src={faqUrl} params={params} token={token} />;
};

export default Privacy;
