import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";

import { fetchClientToken } from "../../store";
import _ from "../../i18n";
import Login from "./AuthInfos/Login";
import Register from "./AuthInfos/Register";
import ResetPassword from "./AuthInfos/ResetPassword";
import styles from "./AuthModal.module.scss";

const AuthModal = ({ lng }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [view, setView] = useState("LOGIN"); // LOGIN | REGISTER | RESET_PASSWORD
  const [email, setEmail] = useState("");
  const [resetParams, setResetParams] = useState(null);

  const { user, clientToken } = auth;

  useEffect(() => {
    if (!clientToken) {
      dispatch(fetchClientToken());
    }
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    setView("LOGIN");
  };

  return (
    <>
      <span className={styles.signinLink} onClick={() => setShowModal(true)}>
        {_("signin")}
      </span>
      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick={false}
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <div className="rmodal">
          <div className={styles.modalClose} onClick={handleCloseModal}>
            <i className="icon-ttp-close-outline"></i>
          </div>
          <div className={styles.modalTop}>
            {view === "LOGIN" && (
              <Login
                showRegister={() => setView("REGISTER")}
                initialEmail={email}
                showNextStep={handleCloseModal}
                showResetPassword={(e) => {
                  setResetParams(e);
                  setView("RESET_PASSWORD");
                }}
              />
            )}
            {view === "REGISTER" && (
              <Register
                closeModal={handleCloseModal}
                showLogin={() => setView("LOGIN")}
                showLoginWithEmail={(e) => {
                  setEmail(e);
                  setView("LOGIN");
                }}
                showNextStep={handleCloseModal}
                // handleLogout={handleLogout}
              />
            )}
            {view === "RESET_PASSWORD" && (
              <ResetPassword
                {...resetParams}
                showLogin={() => setView("LOGIN")}
                showNextStep={handleCloseModal}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AuthModal;
