import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import classNames from "classnames";

import { saveMemberShipCategory } from "../../../../api";
import _ from "../../../../i18n";

import Loader from "../../../common/Loader";
import Button from "../../../common/Button";
import Tabs from "../../../common/Tabs";

import styles from "./AppPopOver.module.scss";

const AppPopOver = ({
  currentApp,
  currentMemberShip,
  closePopOver,
  onSave,
  type,
  parentApp,
}) => {
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const language = useSelector((state) => state.params.language);
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const [isSaving, setIsSaving] = useState(false);
  const [nameFr, setNameFr] = useState("");
  const [nameNl, setNameNl] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [descriptionFr, setDescriptionFr] = useState("");
  const [descriptionNl, setDescriptionNl] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");

  useEffect(() => {
    if (currentApp) {
      setNameFr(currentApp.nameFr);
      setNameNl(currentApp.nameNl);
      setNameEn(currentApp.nameEn);
      setDescriptionFr(currentApp.descriptionFr);
      setDescriptionNl(currentApp.descriptionNl);
      setDescriptionEn(currentApp.descriptionEn);
    }
  }, [currentApp]);

  const validate = () => {
    let errors = [];

    if (!nameFr || !nameNl || !nameEn) {
      errors.push(_("app_name_required"));
    }

    return errors;
  };

  const save = async (e) => {
    let errors = validate();
    if (errors && errors.length > 0) {
      let ErrorsContainer = ({ errors }) => (
        <div>
          <span>{_("errors") + " :"}</span>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      );
      toast.error(<ErrorsContainer errors={errors} />);
      return;
    }

    const data = {
      nameFr,
      nameNl,
      nameEn,
      descriptionFr,
      descriptionNl,
      descriptionEn,
      uaMemberShip: currentMemberShip.id,
      type,
    };

    if (parentApp) {
      data.parent = parentApp.id;
    }

    if (currentApp) {
      data.id = currentApp.id;
    }

    setIsSaving(true);
    saveMemberShipCategory(token, data)
      .then(async (response) => {
        setIsSaving(false);
        onSave();
        toast.success(_("successfully_saved"));
      })
      .catch((e) => {
        setIsSaving(false);
      });
  };

  const handleClose = () => {
    closePopOver();
  };

  return (
    <div>
      <div
        className={classNames(styles.popOver, styles.positioning)}
        onClick={(e) => e.stopPropagation()}
      >
        <div onClick={() => handleClose()} className={styles.close}>
          <i className="icon-ttp-close"></i>
        </div>

        <h3>
          {type === "CATEGORY" &&
            (currentApp ? "Modifier une catégorie" : "Ajouter une catégorie")}
          {type === "SUB_CATEGORY" &&
            (currentApp
              ? "Modifier une sous-catégorie"
              : "Ajouter une sous-catégorie")}
          {type === "OPTION" &&
            (currentApp ? "Modifier une option" : "Ajouter une option")}
        </h3>
        <Tabs theme="wtheme">
          <div
            key={`langtabfr`}
            label={_("french")}
            icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/fr.png`}
          >
            <div>
              <div className={styles.langTab}>
                <label className="ttp-label">{_(`name`) + " FR"}</label>
                <input
                  type="text"
                  className="ttp-input"
                  autocomplete="off"
                  value={nameFr}
                  onChange={(e) => setNameFr(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label className="ttp-label">{_(`description`) + " FR"}</label>
              <textarea
                rows="4"
                className="ttp-input"
                value={descriptionFr}
                onChange={(e) => setDescriptionFr(e.target.value)}
              />
            </div>
          </div>

          <div
            key={`langtabnl`}
            label={_("dutch")}
            icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/nl.png`}
          >
            <div>
              <div className={styles.langTab}>
                <label className="ttp-label">{_(`name`) + " NL"}</label>
                <input
                  type="text"
                  className="ttp-input"
                  autocomplete="off"
                  value={nameNl}
                  onChange={(e) => setNameNl(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label className="ttp-label">{_(`description`) + " NL"}</label>
              <textarea
                rows="4"
                className="ttp-input"
                value={descriptionNl}
                onChange={(e) => setDescriptionNl(e.target.value)}
              />
            </div>
          </div>

          <div
            key={`langtaben`}
            label={_("english")}
            icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/en.png`}
          >
            <div>
              <div className={styles.langTab}>
                <label className="ttp-label">{_(`name`) + " EN"}</label>
                <input
                  type="text"
                  className="ttp-input"
                  autocomplete="off"
                  value={nameEn}
                  onChange={(e) => setNameEn(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label className="ttp-label">{_(`description`) + " EN"}</label>
              <textarea
                rows="4"
                className="ttp-input"
                value={descriptionEn}
                onChange={(e) => setDescriptionEn(e.target.value)}
              />
            </div>
          </div>
        </Tabs>

        <div className={styles.actions}>
          {isSaving ? (
            <Button
              variant="primary"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Loader
                style={{
                  height: "10px",
                }}
                color={"#fff"}
              />
            </Button>
          ) : (
            <Button onClick={save}>
              {currentApp ? _("update") : _("add")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppPopOver;
