import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getTTPUser, getClientCredential } from "../api";
import {
  getAuthInfosFromCookie,
  authHasExpired,
  getCookie,
  logout,
  createAuthCookie,
} from "../utils";
import { APP_ENV } from "../config";
import { setLanguage } from "./index";

const initialState = {
  fetching: false,
  fetched: false,
  token: "",
  clientToken: "",
  expiresIn: null,
  createdAt: null,
  user: null,
  error: null,
  loggedAs: "GUEST",
  loggedAsPost: null,
  loggedIsAdmin: false,
  navCommunity: null,
  callParentAuth: false,
};

export const fetchAutoAuthUser = createAsyncThunk(
  "auth/fetchAutoAuthUser",
  async (user, { getState, dispatch }) => {
    const state = getState();

    const response = await getTTPUser({
      userId: user.id,
      token: user.token,
    });

    return response.data[0];
  }
);

export const fetchAuthUser = createAsyncThunk(
  "auth/fetchAuthUser",
  async (user, { getState, dispatch }) => {
    const state = getState();

    const response = await getTTPUser({
      userId: user.id,
      token: state.auth.token,
    });

    return response.data[0];
  }
);

export const fetchClientToken = createAsyncThunk(
  "auth/fetchClientToken",
  async (user, { getState, dispatch }) => {
    const response = await getClientCredential();
    const helpResp =
      typeof response === "string" ? JSON.parse(response) : response;
    return helpResp.token.access_token;
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (user, { getState, dispatch }) => {
    let authInfos = getAuthInfosFromCookie();
    if (!authInfos || authHasExpired(authInfos)) {
      logout();
    }

    const cookieLang = getCookie(`ttp_lang_${APP_ENV}`);
    if (cookieLang) {
      dispatch(setLanguage(cookieLang));
    } else {
      const lng = localStorage.getItem("lng") || "fr";
      dispatch(setLanguage(lng));
    }

    if (authInfos) {
      dispatch(setAuthToken(authInfos.token));
      dispatch(setExpiresIn(authInfos.expiresIn));
      dispatch(setTokenCreatedAt(authInfos.createdAt));
      dispatch(fetchAuthUser(authInfos));
      // dispatch(fetchFiduciary());
    }
  }
);

export const loginOrClientToken = createAsyncThunk(
  "auth/loginOrClientToken",
  async (user, { getState, dispatch }) => {
    let authInfos = getAuthInfosFromCookie();

    const cookieLang = getCookie(`ttp_lang_${APP_ENV}`);
    if (cookieLang) {
      dispatch(setLanguage(cookieLang));
    } else {
      const lng = localStorage.getItem("lng") || "fr";
      dispatch(setLanguage(lng));
    }

    if (authInfos && !authHasExpired(authInfos)) {
      dispatch(setAuthToken(authInfos.token));
      dispatch(setExpiresIn(authInfos.expiresIn));
      dispatch(setTokenCreatedAt(authInfos.createdAt));
      dispatch(fetchAuthUser(authInfos));
    } else {
      const response = await getClientCredential();
      const helpResp =
        typeof response === "string" ? JSON.parse(response) : response;
      dispatch(setClientToken(helpResp.token.access_token));
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthTokenUser: (state, { payload }) => {
      createAuthCookie({
        token: payload.token,
        user: payload.data.user,
      });
      state.token = payload.token.access_token;
      state.expiresIn = payload.token.expires_in;
      state.createdAt = payload.token.createdAt;
      state.user = payload.data.user;
      state.user.role = payload.data.role;
      if (payload.data.organization && payload.data.organization.length > 0) {
        state.user.communities = [payload.data.organization];
        state.navCommunity = payload.data.organization;
      }

      state.loggedAs =
        payload.data.role && payload.data.role.typeStatus === "FOLDER"
          ? "CLIENT"
          : "COLLABORATOR";
      state.loggedAsPost =
        payload.data.role && payload.data.role.type
          ? payload.data.role.type
          : null;
      if (payload.data.role) {
        if (
          ["LEGAL_REPRESENTATIVE", "MANAGER"].includes(
            payload.data.role.type
          ) &&
          payload.data.role.typeStatus !== "FOLDER"
        ) {
          state.loggedIsAdmin = true;
        }
      }

      if (state.callParentAuth && window.parent) {
        window.parent.postMessage(
          {
            event: "HANDLE_AUTH",
            auth: {
              token: payload.token.access_token,
              expiresIn: payload.token.expires_in,
              createdAt: payload.token.createdAt,
              user: payload.data.user,
            },
          },
          "*"
        );
      }
    },
    setAuth: (state, { payload }) => {
      state = { ...state, payload };
    },
    setAuthUser: (state, action) => {
      state.user = action.payload;
    },
    setAuthLoggedAs: (state, action) => {
      state.loggedAs = action.payload;
    },
    setAuthLoggedAsPost: (state, action) => {
      state.loggedAsPost = action.payload;
    },
    setAuthLoggedIsAdmin: (state, action) => {
      state.loggedIsAdmin = action.payload;
    },
    setExpiresIn: (state, action) => {
      state.expiresIn = action.payload;
    },
    setTokenCreatedAt: (state, action) => {
      state.createdAt = action.payload;
    },
    setAuthToken: (state, action) => {
      state.token = action.payload;
    },
    setClientToken: (state, action) => {
      state.clientToken = action.payload;
    },
    setNavCommunity: (state, action) => {
      state.navCommunity = action.payload;
    },
    setAuthFetched: (state, { payload }) => {
      state.fetched = payload;
    },
    setCallParentAuth: (state, action) => {
      state.callParentAuth = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClientToken.fulfilled, (state, action) => {
      state.clientToken = action.payload;
    });
    builder.addCase(fetchAuthUser.fulfilled, (state, { payload }) => {
      state.user = payload;
      if (payload.communities && payload.communities.length > 0) {
        if (
          payload.role &&
          payload.role.organization.id === payload.communities[0].id &&
          payload.role.organization.owner
        ) {
          state.navCommunity = payload.role.organization.owner;
        } else {
          if (payload.communities.length === 1) {
            state.navCommunity = payload.communities[0];
          } else {
            const community = payload.communities.filter(
              (c) => c.officeType === "ITAA"
            );
            if (community && community.length > 0) {
              state.navCommunity = community[0];
            } else {
              state.navCommunity = payload.communities[0];
            }
          }
        }
      }
      state.loggedAs =
        payload.role && payload.role.typeStatus === "FOLDER"
          ? "CLIENT"
          : "COLLABORATOR";
      state.loggedAsPost =
        payload.role && payload.role.type ? payload.role.type : null;
      if (payload.role) {
        if (
          ["LEGAL_REPRESENTATIVE", "MANAGER"].includes(payload.role.type) &&
          payload.role.typeStatus !== "FOLDER"
        ) {
          state.loggedIsAdmin = true;
        }
      }
      state.fetched = true;
    });
    builder.addCase(fetchAuthUser.rejected, (state, action) => {
      state.fetched = true;
    });
    builder.addCase(fetchAutoAuthUser.fulfilled, (state, { payload }) => {
      createAuthCookie({
        token: {
          access_token: state.token,
          createdAt: state.createdAt,
          expires_in: state.expiresIn,
        },
        user: payload,
      });
      state.user = payload;
      if (payload.communities && payload.communities.length > 0) {
        if (
          payload.role &&
          payload.role.organization.id === payload.communities[0].id &&
          payload.role.organization.owner
        ) {
          state.navCommunity = payload.role.organization.owner;
        } else {
          if (payload.communities.length === 1) {
            state.navCommunity = payload.communities[0];
          } else {
            const community = payload.communities.filter(
              (c) => c.officeType === "ITAA"
            );
            if (community && community.length > 0) {
              state.navCommunity = community[0];
            } else {
              state.navCommunity = payload.communities[0];
            }
          }
        }
      }
      state.loggedAs =
        payload.role && payload.role.typeStatus === "FOLDER"
          ? "CLIENT"
          : "COLLABORATOR";
      state.loggedAsPost =
        payload.role && payload.role.type ? payload.role.type : null;
      if (payload.role) {
        if (
          ["LEGAL_REPRESENTATIVE", "MANAGER"].includes(payload.role.type) &&
          payload.role.typeStatus !== "FOLDER"
        ) {
          state.loggedIsAdmin = true;
        }
      }
      state.fetched = true;
    });
    builder.addCase(fetchAutoAuthUser.rejected, (state, action) => {
      state.fetched = true;
    });
  },
});

export const {
  setAuth,
  setAuthUser,
  setAuthLoggedAs,
  setAuthLoggedAsPost,
  setAuthLoggedIsAdmin,
  setAuthToken,
  setClientToken,
  setExpiresIn,
  setTokenCreatedAt,
  setNavCommunity,
  setAuthTokenUser,
  setAuthFetched,
  setCallParentAuth,
} = authSlice.actions;

export default authSlice.reducer;
